import React, { useRef } from 'react';
import Menu from './Menu';
import UserControl from './UserControl';

const NavPanel = ({ isChecked, checkHandler, confirmLogout }) => {
  return (
        <div className={'nav-holder h-100'}>
          <div className="d-flex gap-3 align-items-baseline">
            <div className='nav-btn'>
              <input
                type="checkbox"
                id="menu_checkbox"
                checked={isChecked}
                onChange={checkHandler}
              />
              <label htmlFor="menu_checkbox">
                <div>
                  <div></div>
                </div>
                <div></div>
                <div>
                  <div></div>
                </div>
              </label>
            </div>
          </div>
          <div className="peili-border no-margin"></div>
          <Menu />
          <UserControl confirmLogout={confirmLogout}/>
        </div>
  );
};

export default NavPanel;
