import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fi, enUS } from 'date-fns/locale';


const CustomDatePicker = ({ selectedDate, onDateChange, attr, label, language }) => {
  const [showNote, setShowNote] = useState(false); // State to control note visibility

  const handleDateChange = (date) => {
    if (date) {
      // Create a new UTC date
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const isoDate = utcDate.toISOString();
      onDateChange(isoDate); // Return the ISO format UTC date

      // Calculate age
      const today = new Date();
      let age = today.getFullYear() - date.getFullYear();
      const monthDiff = today.getMonth() - date.getMonth();
      const dayDiff = today.getDate() - date.getDate();

      // Adjust for cases where the birthday hasn't occurred this year
      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
      }

      // Show the note if age is outside the 4-21 range
      setShowNote(age < 4 || age > 21);
    } else {
      onDateChange(null);
      setShowNote(false); // Reset note if no date is selected
    }
  };

  const today = new Date();
  const minDate = new Date(today.getFullYear() - 50, today.getMonth(), today.getDate());
  const maxDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  return (
    <div className="custom-datepicker">
      <label htmlFor={attr}>{label}</label>
      <DatePicker
        selected={selectedDate ? new Date(selectedDate) : null}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        adjustDateOnChange
        showTimeSelect={false}
        maxDate={maxDate}
        minDate={minDate}
        locale={language === 'fi' ? fi : enUS}
      />
      {showNote && <p className='small-note'>Note: The selected date is outside the age range of 4-21 years old.</p>}
    </div>
  );
};

export default CustomDatePicker;
