import { Route, Routes } from 'react-router-dom';
import PageTitle from './PageTitle';
import Home from './pages/home';
import ViewPatient from './pages/home/professionalUser/viewPatient/index';
import AddPatient from './pages/home/professionalUser/addPatient';
import SessionDetails from './pages/home/professionalUser/sessionDetails';
import EditPatient from './pages/home/professionalUser/editPatient';
import Survey from './pages/survey';
import About from './pages/about';
import Support from './pages/support';
import Layout from './Layout';
import LoginOrRedirect from './LoginOrRedirect';
import RequireAuth from './RequireAuth';
import useAuth from './hooks/useAuth';

const Paths = () => {

    const { auth, setAuth } = useAuth();

    const ROLES = {
        'User': 'UserManager', 
        'Organisation': 'OrganisationManager',
        'Personal' : 'Personal',
        'Medical' : 'MedicalUser',
    }

    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                {/* public routes */}
                <Route path='login' element={<LoginOrRedirect />} />
                
                <Route 
                path="/" 
                element={
                    <>
                    <PageTitle title="EFSim - Home" />
                    <Home />
                    </>
                } 
                />

                <Route 
                path="support"
                element={
                    <>
                    <PageTitle title="EFSim - Support" />
                    <Support />
                    </>
                }
                />
                <Route 
                path="about" 
                element={
                    <>
                    <PageTitle title="EFSim - About" />
                    <About />
                    </>
                } 
                />

                {/* protected routes */}
                <Route element={<RequireAuth allowedRoles={[ROLES.Medical]}/>}>
                <Route 
                path="/" 
                element={
                    <>
                    <PageTitle title="EFSim - Home" />
                    <Home />
                    </>
                } 
                />  
                <Route 
                path="about" 
                element={
                    <>
                    <PageTitle title="EFSim - About" />
                    <About />
                    </>
                } 
                />

                    <Route 
                        path="add-patient"
                        element={
                            <>
                            <PageTitle title="EFSim - Add Patient" />
                            <AddPatient />
                            </>
                        }
                    />

                    {/* Direct ViewPatient route */}
                    <Route 
                        path="view-patient" 
                        element={
                            <>
                            <PageTitle title="EFSim - View Patient" />
                            <ViewPatient />
                            </>
                        }
                    />


                        {/* Direct ViewPatient route */}
                    <Route 
                        path="edit-patient" 
                        element={
                            <>
                            <PageTitle title="EFSim - Edit Patient" />
                            <EditPatient />
                            </>
                        }
                    />

                    <Route 
                        path="view-patient/session-details" 
                        element={
                            <>
                            <PageTitle title="EFSim - Test Result" />
                            <SessionDetails />
                            </>
                        }
                    />
                </Route>
            </Route>
        </Routes>
    )
}

export default Paths;
