import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

const ResultInterpretation = (props) => {

    const { t } = useTranslation();

    const cancel = () => {
        props.setPopupState('');
    }
    
    return (
        <section className={"peili-popup-wrapper secondary-purple"}>
            <h4 className='mb-4 text-secondary-purple'>
               {t("Result Interpretation")}
            </h4>

            <div className='mb-3 smaller-text'>
                {t("The table lists the absolute values (RP) and values relative to the selected reference group of neurocognitive performance indicators collected by EFsim. For normally distributed performance indicators, the Z column reports Z-scores, i.e., the deviation from the reference group's mean in standard deviations.")}
            </div>

            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header> {t("Total Score")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("Number of subtasks performed correctly and on time (+/- 10 seconds from the given time)")}
                        </div>
                        <div>
                            - {t("General measure of prospective memory in multitasking situations")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("Task efficacy")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("The ratio of essential actions to all actions, excluding movement")}
                        </div>
                        <div>
                            - {t("Measure of attention and executive functioning skills in complex situations")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t("Navigation efficacy")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("The ratio of correctly performed subtasks to the amount of movement")}
                        </div>
                        <div>
                            - {t("Measure of the planning of movement")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("Total actions")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("Total number of actions, including movement")}
                        </div>
                        <div>
                            - {t("Measure of motor activity and impulsive behavior")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>{t("Time-based subtasks")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("Number of timed subtasks performed correctly and on time (+/- 10 seconds from the given time)")}
                        </div>
                        <div>
                            - {t("Measures the ability to perform tasks requiring temporal precision in prospective memory")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>{t("Number of clock checks")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("How many times the user checked the time on the clock during the test")}
                        </div>
                        <div>
                            - {t("Measure of time management and monitoring the passage of time")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>{t("Event-based prospective memory subtasks")}</Accordion.Header>
                    <Accordion.Body>
                        <div>
                            - {t("Number of subtasks performed correctly and on time (within 10 seconds), to be completed after an external cue")}
                        </div>
                        <div>
                            - {t("Ability to respond to external memory cues")}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="buttons-wrapper d-flex mt-5 justify-content-center">
                <button className='button cancel-btn' onClick={() => cancel() }>{t("Close")}</button> 
            </div>
        </section>
    )
}

export default ResultInterpretation