const AddExerciseSession = async (axiosPrivate, patientId) => {
    try {
        await axiosPrivate.post(
            `/patients/${patientId}/exercisesessions`,
            JSON.stringify({
                ExerciseSessionSettings: null,
            })
        );
    } catch (err) {
        console.error("Exercise session creation failed", err);
        throw err;
    }
};
export default AddExerciseSession;
