import { useRef, useState } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';

const DeletePatient = ({ setPopupState, patientId }) => {
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation();
    const DELETE_PATIENT_URL = `/Patients/${patientId}`;
    const navigate = useNavigate();

    const popupRef = useRef(); 
    useOnClickOutside(popupRef, () => setPopupState(''));
    
    const [errMsg, setErrMsg] = useState('');
    const errRef = useRef();

    const cancelDelete = (e) => {
        e.preventDefault();
        setPopupState('');
    };

    const approveDelete = async (e) => {
        e.preventDefault();
        setErrMsg('');

        try {
            await axiosPrivate.delete(DELETE_PATIENT_URL);
            navigate(`/`);
        } catch (err) {
            handleErrors(err);
        }
    };

    const handleErrors = (err) => {
        if (!err?.response) {
            setErrMsg('No server response');
        } else if (err.response?.status === 400) {
            setErrMsg(err.response.data);
        } else if (err.response?.status === 401) {
            setErrMsg('401 Unauthorized');
        } else {
            setErrMsg('Request failed');
        }
        errRef.current.focus();
    };

    return (
        <section className="peili-popup-wrapper red" ref={popupRef}>
            <div className="delete-note text-center">
                <h5>{t("Are you sure you want to delete the patient?")}</h5>
                <h5>{t("This action cannot be undone.")}</h5>
            </div>
            <div className="col-md-12">
                <div className="buttons-wrapper d-flex gap-3 mt-5 justify-content-center">
                    <button className="button cancel-btn" onClick={cancelDelete}>{t("Cancel")}</button>
                    <button className="button btn-green" onClick={approveDelete}>{t("Delete Patient")}</button>
                </div>
            </div>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                {errMsg}
            </p>
        </section>
    );
};

export default DeletePatient;
