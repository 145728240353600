import { useState, useEffect, useRef } from 'react';
import PatientTable from '../../../components/tables/PatientTable';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { convertToEnDateFormat } from '../../../components/util/utils.js';
import { addPatientIcon } from '../../../icons/index.js';
import { ReactSVG } from "react-svg";

const ProfessionalUser = () => {
    const { auth, setAuth } = useAuth();
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const [ patientList, setPatientList ] = useState('');
    const inputRef = useRef(null);
    const [ filteredData, setFilteredData ] = useState('');
    const viewFirst = useRef('active');
    const [init, setInit] = useState(false);

    useEffect(() => {
        setInit(false);
    }, [init]);

    useEffect(() => {
        getPatients();
    }, [auth]);


    const getPatients = async () => {
        try {
            const response = await axiosPrivate.get('/user/patients',  {
                //signal: controller.signal
            });

            let responseData = response.data.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate));
            
            setFilteredData(responseData);
            setPatientList(responseData);
        } catch(err){
            //console.error(err);
        }
    }

    const handleSearch = (e) => {
        let value = e.target.value.toLowerCase();
        let result = patientList.filter((data) => {
            return (
                data.firstName.toLowerCase().includes(value) || 
                data.lastName.toLowerCase().includes(value) || 
                convertToEnDateFormat(data.birthDate).toLowerCase().includes(value)

            );
        });
        
        setFilteredData(result);
    };    

    
    return ( 
        <>
            <div className='peili-main-wrapper'>
                <div className="peili-views-wrapper">
                    <h1>{t("Home")}</h1>
                    <div className="peili-border"></div>
                    <section className={'peili-content-wrapper view-1 ' + viewFirst.current}>
                        <div className="row">
                            <div className='col-md-8 mb-4'>
                                <div className='search-container d-inline-flex'>
                                    <div className='search-wrapper'>
                                        <input type="search" placeholder={t("SEARCH")} onChange={(e) => handleSearch(e)} ref={inputRef}></input>
                                    </div>
                                </div>
                            </div> 
                            <div className="col-md-4 mb-4">
                                <div className='d-flex flex-row justify-content-end'>
                                    <Link className="button btn-green" to={'/add-patient'}>
                                        <ReactSVG src={addPatientIcon} />
                                        <span>{t("ADD PATIENT")} </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {filteredData?.length
                            ? (
                                <PatientTable data={filteredData} setInitState={setInit}/>
                            ) : <p>{t("NO PATIENT TO DISPLAY")}</p>  
                        }
                    </section>
                </div>
            </div>
        </>
    ) 
}

export default ProfessionalUser;