import { createContext, useState, useEffect } from "react";
import i18next from 'i18next';


const AuthContext = createContext({});

export const AuthProvider = ({ children }) =>  {
    const [auth, setAuth] = useState({});

    useEffect(() => {
        const storedAuth = localStorage.getItem('auth');
        const lang = localStorage.getItem('i18nextLng');
        if (storedAuth) {
            setAuth(JSON.parse(storedAuth));
            i18next.changeLanguage(lang);
        }
        
    }, []);

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;

