import React from "react";
import { ScatterChart, Scatter, XAxis, 
    YAxis, CartesianGrid, ZAxis,Tooltip, Label, ResponsiveContainer,  } from 'recharts';

const ScatterPlot = (props) => {
    const data = props.data;
    const height = props.height;
    const margin = props.margin;

    const xhide = props.xhide;
    const xdomain = props.xdomain;
    const xtick = props.xtick;
    const xtickCount = props.xtickCount;

    const ydomain = props.ydomain;
    const ytick = props.ytick;
    const ytickCount = props.ytickCount;

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
                <p className="value">{`${payload[0].payload.z}`}</p>
                <p className="desc">Z score</p>
            </div>
          );
        }
        return null;
    };

    const inRangeResult = Object.values(data)
       .filter((data) => data.z >= -3 && data.z <= 3);

    const outRangeResult = Object.values(data)
       .filter((data) => data.z <= -3.1 || data.z >= 3.1);

    return (
        <>
            <ResponsiveContainer width="100%" height={height}>
                <ScatterChart margin={margin} >
                    <CartesianGrid/>
                    <XAxis dataKey="x" type="number" hide={xhide} name="Standard Deviation" orientation="top"  domain={xdomain} tickCount={xtickCount} tick={xtick}>
                        <Label value="Z" offset={0} position="top" />
                    </XAxis>
                    <YAxis dataKey="y" type="number"  hide="false" domain={ydomain} tickCount={ytickCount}  tick={ytick}/>
                    <Tooltip wrapperStyle={{zIndex: 1000}} content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }}  />
                    <ZAxis dataKey="z" type="number"  name="score" />
                    <Scatter data={inRangeResult} fill="#7E28B6" />
                    <Scatter className={"wings"} data={outRangeResult} fill="#7E28B6" />
                </ScatterChart>
            </ResponsiveContainer>
        </>
    )
}

export default ScatterPlot