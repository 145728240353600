import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";
import { useTranslation } from 'react-i18next';
import { sortUp, sortDown } from '../../icons';
import { convertToEnDateFormat, convertToFiDateFormat, isEng } from "../util/utils.js";

const PatientTable = ({ data, openViewPatient }) => { 
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8); 
    const [sortDirection, setSortDirection] = useState('asc');
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const handleResize = () => {
        if (window.innerHeight < 800) {
            setRowsPerPage(5)
        } else if (window.innerHeight < 850) {
            setRowsPerPage(6)
        } else if (window.innerHeight < 1000) {
            setRowsPerPage(7)
        } else if (window.innerHeight < 1200) {
            setRowsPerPage(10)
        } else if (window.innerHeight < 1500) {
            setRowsPerPage(15)
        }
    }

    useEffect(() => {
        handleResize();

    }, [rowsPerPage]);

    // Function to toggle sorting direction
    
    const handleSort = () => {
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        if (newSortDirection === 'asc') {
            data.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate));

        } else {
            data.sort((a, b) => new Date(a.modifiedDate) - new Date(b.modifiedDate));
        }
    }

    const indexOfLastItem = page * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const findLanguage = (val) => {
        const languageObject = {
            'fi-FI': 'Finnish',
            'sv-SE': 'Swedish',
            'de-DE': 'German',
            'fr-FR': 'French',
            'en-GB': 'English',
            'en-US': 'English'
        };
        return languageObject[val] || "Finnish";
    };

    const viewPatient = (e, id) => {
        e.preventDefault();
        navigate(`/view-patient?id=${id}`);
    }

    return (
      <>
        <table className="peili-table peili-users-table">
            <thead>
                <tr>
                    <th className='table-header'>{t("First Name")} </th>
                    <th className='table-header'>{t("Last Name")} </th>
                    <th className='table-header'>{t("Date of Birth")} </th>
                    <th className='table-header'>{t("Gender")} </th>
                    <th className='table-header'>{t("Language")} </th>
                    <th className='table-header' onClick={handleSort}>
                        {t("Date modified")}
                        <img className="sort-icon" src={sortDirection === 'asc' ? sortUp : sortDown} alt="Sort Icon" /> {/* Display sort icon */}
                    </th>

                </tr>  
            </thead> 
            <tbody>
            {currentItems.map((el, i) =>  
                <tr key={"user-" + i} onClick={(e) => {viewPatient(e, el.id)}}>
                    <td>{el?.firstName}</td>
                    <td>{el?.lastName}</td> 
                    <td>{isEng() ? convertToEnDateFormat(el?.birthDate) : convertToFiDateFormat(el?.birthDate)}</td>
                    <td>{(t(el?.gender))}</td>
                    <td>{t(findLanguage(el?.language))}</td>
                    <td>{isEng() ? convertToEnDateFormat(el?.modifiedDate) : convertToFiDateFormat(el?.modifiedDate)}</td>
                </tr>
            )}
            </tbody>
        </table>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}/>
     </>
    );
};

export default PatientTable;
