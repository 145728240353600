
import React, { useEffect } from "react";

const TableFooter = ({ range, setPage, page, slice, setRowsPerPage, rowsPerPage }) => {

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <div className='table-footer'>
      {range.map((el, index) => (
        <button
          key={index}
          className={`pagination-btn ${
            page === el ? 'active' : 'inactive'
          }`}
          onClick={() => setPage(el)}
        >
          {el}
        </button>
      ))}
      <select className="rows-per-page-btn d-none" type="number" value={rowsPerPage} placeholder="Rows per page..." onChange={(e) => { setRowsPerPage(e.target.value)} }>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option> 
      </select> 
    </div>
  );
};

export default TableFooter;
