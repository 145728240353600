import React, { useState } from 'react';
import LoadingRing from '../components/LoadingRing';
import { useTranslation } from 'react-i18next';

const ForgotPassword = ({
  t,
  user,
  handleBack,
  forgotPassword,
  userRef,
  setUser,
  viewThird,
  forgotPassSuccess,
  onLoginClick 
}) => {
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    await forgotPassword(e);
    setLoading(false);
  };

  return (
    <div className={"peili-content-wrapper forgot-password collapse " + viewThird.current}>
      <div className="forgot-password-body ">
        <div className='btn-holder'>
          <button className='button btn-orange' onClick={handleBack}>{t("BACK")}</button>
        </div>
        <form onSubmit={handleForgotPassword} autoComplete="off">
          <h2>{t("Forgot Password")}?</h2>
          <div className='authentication-note'>
            <p>{t("Enter your email and we'll send you a link to reset your password")}.</p>
          </div>
          <div className='item'>
            <label htmlFor="username">{t('Email')}</label>
            <input
              type="text"
              id="username"
              ref={userRef}
              autoComplete="off"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>
          {!loading && !forgotPassSuccess.current && <button className='button mobile-adj'>{t('Send')}</button>}
          {loading && <LoadingRing smallLoader="true" />}
        </form>
      </div>
      <div className={"text-center mt-5 collapse " + forgotPassSuccess.current}>
        {t("Success! Reset password has been sent to your email")}.
        <button
          className='word-button primary-blue no-line'
          onClick={() => handleBack()} 
        >
          {t("Login here")}.
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;
