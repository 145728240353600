import React, { useState } from 'react';
import { HiOutlineLightBulb } from "react-icons/hi2";
import { GoTriangleDown } from "react-icons/go";

// WITH TOGGLING

const ChildPage = ({ block, handleChildPageClick, activeChild }) => {
  const [showChildren, setShowChildren] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  // const toggleChildren = () => {
  //     setShowChildren(!showChildren);
  //   if (!showChildren && block.content.childPages) {
  //     handleChildPageClick(block.content.child_id);
  //   }
  // };

  const toggleChildren = () => {
    //console.log('double clicked');
    setShowChildren(!showChildren);
  }

  const childPageClick = () => {
    if(!showChildren && block.content.childPages) {
      handleChildPageClick(block.content.child_id);
    }
  }

  const handleParagraphClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="child_page_container">
      <p 
        className={activeChild === block.content.child_id ? 'active' : "paragraph - child_page"}
        style={{ cursor: "pointer"}}
        onClick={() => {
          childPageClick();
          handleChildPageClick(block.content.child_id);
          // toggleChildren();
        }}
        onDoubleClick={toggleChildren}
      >
        <HiOutlineLightBulb />
        {block.content.child_title}
      </p>
      
      {!showChildren && block.content.childPages && (
      <div style={{ marginLeft: '20px' }} >
        {block.content.childPages.map((child, childPageIndex) => (
        <div
          key={childPageIndex}
          onClick={() => handleParagraphClick(childPageIndex)}  // childPageIndex to include the child pages in the highlighting logic
        >
          <ChildPage 
            block={{ content: child }} 
            handleChildPageClick={handleChildPageClick}
            activeChild={activeChild}
          />
        </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default ChildPage;


// WITHOUT TOGGLING

// const ChildPage = ({ block, handleChildPageClick, activeChild }) => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const handleParagraphClick = (index) => {
//     setActiveIndex(index);
//   };

//   return (
//     <div className="child_page_container">
//       <p 
//         className={ activeChild === block.content.child_id ? 'active' : "ptest"}
//         onClick={() => {
//           handleChildPageClick(block.content.child_id);
//         }} 
//         style={{ cursor: "pointer" }}
//       >
//         <HiOutlineLightBulb/> 
//         {block.content.child_title}
//       </p>
//       {block.content.childPages && (
//         <div style={{ marginLeft: '20px' }} className="child_page_content">
//           {block.content.childPages.map((child, childPageIndex) => (
//             <div
//               key={childPageIndex}
//               onClick={() => handleParagraphClick(childPageIndex)}
//               >
//               <ChildPage 
//                 block={{ content: child }} 
//                 handleChildPageClick={handleChildPageClick}
//                 activeChild={activeChild}
//               />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default ChildPage;



