import React from 'react';

import {Accordion} from '@mui/material';
import {AccordionDetails} from '@mui/material';
import {AccordionSummary} from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const renderTextWithAnnotations = (text, annotations) => {
    let styledText = text;

    if (annotations.bold) styledText = <strong>{styledText}</strong>;
    if (annotations.italic) styledText = <em>{styledText}</em>;
    if (annotations.strikethrough) styledText = <s>{styledText}</s>;
    if (annotations.underline) styledText = <u>{styledText}</u>;
    if (annotations.code) styledText = <code>{styledText}</code>;
    if (annotations.color && annotations.color !== 'default') {
      if (annotations.color.endsWith('_background')) {
        const bgColor = annotations.color.split('_')[0];
        styledText = <span style={{ backgroundColor: bgColor }}>{styledText}</span>;
      } else {
        styledText = <span style={{ color: annotations.color }}>{styledText}</span>;
      }
    } 
    return styledText;
  };

  export const renderRichTextArray = (richTextArray) => {
    return richTextArray.map((richText, index) => {
      return richText.link ? (
        <a href={richText.link} key={index} target="_blank" rel="noopener noreferrer">
          {renderTextWithAnnotations(richText.text, richText.annotations)}
        </a>
      ) : (
        <span key={index}>
          {renderTextWithAnnotations(richText.text, richText.annotations)}
        </span>
      );
    });
  };

  const ImageList = ({ children }) => {
    return (
        <div>
          {children.map((child, index) => (
            child.type === 'image' && (
              <figure key={index}>
                <img
                  className="notion_image_column"
                  src={child.content}
                  alt={`Notion Image ${index}`}
              />
              {child.caption && (
                <figcaption>{child.caption}</figcaption>
              )}
              </figure>
            )
          ))}
        </div>
    );
  };
  
  export const renderBlock = (block, index) => {
    // console.log("Rendering block:", block);

    switch (block.type) {
      case 'paragraph':
      case 'heading_1':
      case 'heading_2':
      case 'heading_3':
        const Tag = block.type === 'paragraph' ? 'p' : `h${block.type.split('_')[1]}`;
        return (
          <Tag key={index}>
            {renderRichTextArray(block.content)}
          </Tag>
        );
      case 'bulleted_list_item':
        const renderBulletChildren = (children) => {
          return children.map((child, childIndex) => {
            if (child.children && child.children.length > 0) {
              return (
                <li key={childIndex}>
                  { renderRichTextArray(child.text) }
                    <ul>
                      { renderBulletChildren(child.children) }                      
                    </ul>
                </li>
              );
            } else {
              return <li key={childIndex}>{ renderRichTextArray(child.text) }</li>
            }
          })
        }
        return (
          <ul key={index}>
            <li key={index}>
              { renderRichTextArray(block.content.bullet_parent) }
              {block.content.bullet_children && block.content.bullet_children.length > 0 ? (
                <ul>
                    { renderBulletChildren(block.content.bullet_children) }
                </ul>
              ) : null }
            </li>
          </ul>
        );
      case 'numbered_list_item':
        return (
            <ol key={index}>
                {block.content.map((item, itemIndex) => (
                    <li key={itemIndex}>
                      {renderRichTextArray([item.text])}
                    </li>
                ))}
            </ol>
        );
      case 'quote': 
        return (
          <figure className="quote" key={index}>
            <blockquote>
              {renderRichTextArray(block.content)}
            </blockquote>
          </figure>
        );
      case 'callout': 
        return (
          <p className="callout" key={index}>
            {block.content.quote_icon && <span className="icon">{block.content.quote_icon}</span>}
            {block.content.quote_title.map((quote_title, textIndex) =>
              quote_title.link ? (
                <a href={quote_title.link} key={textIndex} target="_blank" rel="noopener noreferrer">
                  {renderTextWithAnnotations(quote_title.text, quote_title.annotations)}
                </a>
              ) : (
                <span key={textIndex}>{renderTextWithAnnotations(quote_title.text, quote_title.annotations)}</span>
              ))}
            {block.content.callout_children.length > 0 && block.content.callout_children.map((child, childIndex) => (
              <div key={childIndex} className={`callout-child ${child.type}`}>
                {child.type === 'quote' ? (
                  <blockquote>
                    {/* Render each quote's content */}
                    {child.content.map((textItem, textIndex) => (
                      <span key={textIndex}>{renderTextWithAnnotations(textItem.text, textItem.annotations)}</span>
                    ))}
                  </blockquote>
                ) : (
                  <p>
                    {/* Render non-quote content (paragraphs, headings) */}
                    {child.content.map((textItem, textIndex) => (
                      <span key={textIndex}>{renderTextWithAnnotations(textItem.text, textItem.annotations)}</span>
                    ))}
                  </p>
                )}
              </div>
            ))}
          </p>
        );
      case 'table':
        return (
          <table className="table" key={index}>
            <tbody>
              {block.content.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      {cell.map((richText, richTextIndex) =>
                        richText.link ? (
                          <a href={richText.link} key={richTextIndex} target="_blank" rel="noopener noreferrer">
                            {renderTextWithAnnotations(richText.text, richText.annotations)}
                          </a>
                        ) : (
                          <span key={richTextIndex}>{renderTextWithAnnotations(richText.text, richText.annotations)}</span>
                        ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
        case 'column_list':
        return (
          <div className="row" key={index}>
            {block.content.column_children.map((column_child, columnIndex) => (
              <div className="column" key={columnIndex}>
                {column_child.map((child, childIndex) => (
                  child.type !== 'image' && (
                    renderBlock(child, childIndex)
                  )
                ))}
                <ImageList children={column_child} />
              </div>
            ))}
          </div>
        );
        case 'toggle':
          const renderToggleChildren = (children) => {
            return children.map((child, childIndex) => {
              if (child.children && child.children.length > 0) {
                // Render as Accordion if there are subChildren
                return (
                  <Accordion key={childIndex} className="accordion">
                    <AccordionSummary id="panel-header" aria-controls="panel-content" key={childIndex} expandIcon={<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>}>
                      <p>{ renderRichTextArray(child.text) }</p>
                    </AccordionSummary>
                    {child.children && child.children.length > 0 ? (
                      <AccordionDetails>
                        { renderToggleChildren(child.children) }
                      </AccordionDetails>
                      ) : null}
                  </Accordion>
                );
              } else {
                // Render as normal text if there are no subChildren
                return <p key={childIndex}>{ renderRichTextArray(child.text) }</p>
              }
          });
          };

          return (
            <Accordion className="accordion">
              <AccordionSummary id="panel-header" aria-controls="panel-content" key={index} style={{ fontWeight: "bold" }} expandIcon={<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>}>
                <p>{ renderRichTextArray(block.content.toggle_parent) }</p>
              </AccordionSummary>
              {block.content.toggle_children && block.content.toggle_children.length > 0 ? (
                <AccordionDetails>
                  { renderToggleChildren(block.content.toggle_children) }              
                </AccordionDetails>
              ) : null }
            </Accordion>
          );
      case 'image':
      return (
        <figure key={index}>
          <img
            className="notion_image"
            width="100%"
            src={block.content.imageUrl}
            alt={`Notion Image ${index}`}
          />
          <figcaption>{block.content.caption}</figcaption>
        </figure>
        );
      case 'video':
        return <iframe width="250" src={block.content} key={index} style={{ alignSelf: "center" }} />;
      case 'pdf':
        return <embed width="100%" height="400" type="application/pdf" src={block.content} key={index} style={{ maxWidth: '900px', border: 'none' }}/>;
      case 'embed':
        return <iframe maxwidth="250" src={block.content} key={index} />;
      case 'divider':
        return <hr style={{ borderTop: "1px solid black", width: "100%" }} key={index} />;
      case 'child_page':
        return null;
      default:
        return <span key={index}>Unknown block type</span>;
    }
  };