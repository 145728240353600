import { useEffect, useRef, useState } from 'react';
import { ReactSVG } from "react-svg";
import { useTranslation } from 'react-i18next';

function NavToolTip({ label, icon }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const { t } = useTranslation();

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="nav-item" ref={tooltipRef}>
      <a onClick={toggleTooltip} role="button" aria-expanded={showTooltip}>
        <ReactSVG src={icon} />
        <span>{label}</span>
      </a>

      {showTooltip && (
        <div className="tooltip-content">
          <ul>
            <li>
              <a
                href="mailto:support@efsimtest.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Send email to support"
              >
                support@efsimtest.com
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default NavToolTip;
