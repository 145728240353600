import { useState, useEffect, useRef } from 'react';
import PatientTable from '../../../components/tables/PatientTable';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import TestTable from '../../../components/tables/TestTable';
import OnboardingPopup from './OnboardingPopup';
import InfoGraph from './InfoGraph';
 
const ParentUser = () => {
    
    const { auth, setAuth } = useAuth();
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const [ currentPatient, setCurrentPatient ] = useState("");
    const [ patientList, setPatientList ] = useState('');
    const inputRef = useRef(null);
    const [ filteredData, setFilteredData ] = useState('');
    const [ filterBy, setFilterBy ] = useState('name');
    
    const [ sessionDataFetched, setSessionDataFetched ] = useState('');
    const [loadingRing, setLoadingRing] = useState(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>);

    const viewFirst = useRef('active');
    const viewSecondary = useRef('');
    const viewThird = useRef('');
    
    const [ viewPatient, setViewPatient ] = useState();

    const [init, setInit] = useState(false);

    useEffect(() => {
        setInit(false);
        //loggedinTimeout();
    }, [init]);

    useEffect(() => {
        getPatients();
        //getAllSessions();
    }, [auth]);

    /*const loggedinTimeout = () => {
        const now = new Date().getTime();
        const loggedinTime = localStorage.getItem('loggedinTime');
        if(now-loggedinTime > 23*60*60*1000) {
             localStorage.clear();
             localStorage.setItem('tokenExpire', true);
             window.location.reload();
        }
    }*/

    const goBack = () => {
        setInit(true);
        setViewPatient('');
        getPatients();
        inputRef.current.value = "";
        viewFirst.current = 'active';
        viewSecondary.current = '';
        viewThird.current = '';
    }

    const openAddUserForm = () => {
        setInit(true);
        if(viewSecondary.current === 'active' || viewThird.current === 'active'){
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
        } else {
            viewFirst.current = '';
            viewSecondary.current = '';
            viewThird.current = 'active';
        }
        
    }

    function openViewPatient(e,patient){
        if(viewFirst.current === 'active' || viewThird.current === 'active'){
            setCurrentPatient(patient);
            setViewPatient('viewPatient');
            viewFirst.current = '';
            viewSecondary.current = 'active'; 
            viewThird.current = '';
        } else {
            setViewPatient('');          
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
        }
    }

    const getPatients = async () => {
        try {
            const response = await axiosPrivate.get('/Users/' + auth.id + '/Patients', {
                // signal: controller.signal
            });
    
            let responseData = response.data.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate));
            let updatedPatientList = [];
    
            for (let i = 0; i < responseData.length; i++) {
                const item = responseData[i];
    
                try {
                    const sessionResponse = await axiosPrivate.get('/patients/' + item.id + '/exercisesessions', {
                        // signal: controller.signal
                    });
    
                    const data = sessionResponse.data;
    
                    if (data.length > 0) {
                        item.sessionData = data;
                    }
                } catch (err) {
                    console.error('Error fetching session data for patient:', item.id, err);
                }
    
                updatedPatientList.push(item);
            }
    
            setFilteredData(updatedPatientList);
            setPatientList(updatedPatientList);
            setSessionDataFetched('loaded');
    
        } catch (err) {
            console.error('Error fetching patients:', err);
        }
    };




    const selectedFilter = (selected) => (event) => {
        setFilterBy(selected);
    }

    const handleSearch = (e) => {
        let value = e.target.value.toLowerCase();
        let result = [];
        if(filterBy === "email"){
            result = patientList.filter((data) => {
                if( data.email.toLowerCase().search(value) !== -1 ) {
                    return data.email.toLowerCase().search(value) !== -1;
                } else {
                    return data.email.toLowerCase().search(value) !== -1;
                }
            });
        }else if(filterBy === "contact"){
            result = patientList.filter((data) => {
                if( data.phoneNumber.toLowerCase().search(value) !== -1 ) {
                    return data.phoneNumber.toLowerCase().search(value) !== -1;
                } else {
                    return data.phoneNumber.toLowerCase().search(value) !== -1;
                }
            });
        }
        else{
            result = patientList.filter((data) => {
                if( data.nickname.toLowerCase().search(value) !== -1 ) {
                    return data.nickname.toLowerCase().search(value) !== -1;
                } else {
                    return data.nickname.toLowerCase().search(value) !== -1;
                }
            });
        }
            
        setFilteredData(result);
    }

    const setCurrentPatientDetails = (details) => {
        setCurrentPatient(details);
    }
    
    return ( 
        <>
            <div className='peili-main-wrapper'>

                { auth.welcomeOnboardingShownDate === "0001-01-01T00:00:00" ? <OnboardingPopup /> : <></> }

                <div className="peili-views-wrapper parent-view">
                    <h1>{t("Welcome to EFSim test")}</h1>
                    <div className="peili-border"></div>
                    
                    <section className={'peili-content-wrapper view-1 ' + viewFirst.current}>

                        <div className="row">
                            
                            <div className='col-7 mb-4 border-right custom-column'>
                            {sessionDataFetched === '' ? loadingRing : 
                                filteredData?.length
                                    ? (
                                        <TestTable data={filteredData} loaded={sessionDataFetched} openViewPatient={openViewPatient} setInitState={setInit}/>
                                    ) : <p>{t("No patients to display")}</p>  
                                
                            }
                            </div> 
                            
                            <div className="col-5 mb-4 custom-column">
                                <InfoGraph></InfoGraph>
                            </div>
                        </div>

                    </section>

                    <section className={'peili-content-wrapper view-2 ' + viewSecondary.current}>

                    </section>

                    <section className={'peili-content-wrapper view-3 ' + viewThird.current}>
                       
                    </section>
                </div>
            </div>
        </>
    ) 
}

export default ParentUser;