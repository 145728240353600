import React, { useState, useEffect, useRef } from 'react';
import {renderBlock} from '../../notion/notion_blocks_handlers';
import { aboutImage } from '../../images';


const About = ({country}) => {
    const [fetchedData, setFetchedData] = useState(null);

    const dataRef = useRef({});

    const getPageId = (country) => {

        const pageIds = [
            {country_code: "en", pageId: "4b5c3e36933d42d6b4cef1fd3bd4d04f"},
            {country_code: "fr", pageId: "4b5c3e36933d42d6b4cef1fd3bd4d04f"}, // The same pageId for French at the moment but when there's translation, here's where to add the corresponding page ID (from the URL)
        ];

        if(!country) {
            country = localStorage.getItem("country");
        }

        const page = pageIds.find(p => p.country_code === country);

        if (page) {
            //console.log(`${country.toUpperCase()} + ${country}`);
            localStorage.setItem("country", country);
            return page.pageId;
        } else {
            //console.log("Invalid country");
            return pageIds.find(p => p.country_code === "en").pageId; // Default to English pageId
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const pageId = getPageId(country);
    
            // Check if data is already cached
            const cachedData = localStorage.getItem('about-page');
            if (cachedData) {
                setFetchedData(JSON.parse(cachedData));
                //console.log("Loaded data from localStorage");
            }else{
                if (dataRef.current[pageId]) {
                    setFetchedData(dataRef.current[pageId]);
                } else {
                    try {
                        const response = await fetch(`/fetchDataFromNotion/${getPageId(country)}`, {mode: 'no-cors'});
                        
                        // Check if the response is JSON
                        const contentType = response.headers.get('content-type');
                        if (contentType && contentType.includes('application/json')) {
                            const data = await response.json();
                            setFetchedData(data);
                            // Cache the fetched data
                            dataRef.current[pageId] = data;
                            localStorage.setItem('about-page', JSON.stringify(data));
                        } else {
                            // Handle non-JSON response
                            console.error('Received non-JSON response:', await response.text());
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
            }
            
        };
    
        fetchData();
      }, [country]);
    return ( 
        <>
           <div className='peili-main-wrapper'>
                <div className="peili-views-wrapper">
                    {fetchedData ? (
                      <>
                        <h1 className="main_h1_kb"> {fetchedData.title || "No title available"}</h1>
                        <div className="peili-border"></div>
                        <section className='page-about'> 
                            <div className=' peili-card with-image d-flex '>
                                <div className="p-4">
                                    {fetchedData.processedContent.map((block, fetchedDataIndex) =>
                                    <div key={fetchedDataIndex}>
                                        {renderBlock(block, fetchedDataIndex)}
                                    </div> 
                                    )}
                                </div>
                                <div className='image-holder'>
                                    <img alt="about-peili" src={aboutImage}></img>
                                </div>
                            </div>
                        </section>
                
                      </>
                      ) : ( 
                      <p>Loading...</p>
                    )}

                    
                   
                </div>
            </div>
        </>
    ) 
}

export default About;