import React from 'react';

export const renderTextWithAnnotations = (text, annotations) => {
    let styledText = text;

    if (annotations.bold) styledText = <strong>{styledText}</strong>;
    if (annotations.italic) styledText = <em>{styledText}</em>;
    if (annotations.strikethrough) styledText = <s>{styledText}</s>;
    if (annotations.underline) styledText = <u>{styledText}</u>;
    if (annotations.code) styledText = <code>{styledText}</code>;
    if (annotations.color && annotations.color !== 'default') {
      if (annotations.color.endsWith('_background')) {
        const bgColor = annotations.color.split('_')[0];
        styledText = <span style={{ backgroundColor: bgColor }}>{styledText}</span>;
      } else {
        styledText = <span style={{ color: annotations.color }}>{styledText}</span>;
      }
    } 
    return styledText;
  };

  export const renderRichTextArray = (richTextArray) => {
    return richTextArray.map((richText, index) => {
      return richText.link ? (
        <a href={richText.link} key={index} target="_blank" rel="noopener noreferrer">
          {renderTextWithAnnotations(richText.text, richText.annotations)}
        </a>
      ) : (
        <span key={index}>
          {renderTextWithAnnotations(richText.text, richText.annotations)}
        </span>
      );
    });
  };

  export const renderBlock = (block, index) => {
    switch (block.type) {
      case 'paragraph':
      case 'heading_1':
      case 'heading_2':
      case 'heading_3':
        const Tag = block.type === 'paragraph' ? 'p' : `h${block.type.split('_')[1]}`;
        return (
          <Tag key={index}>
            {renderRichTextArray(block.content)}
          </Tag>
        );
      case 'bulleted_list_item':
        return (
          <ul key={index}>
            <li>
              {renderRichTextArray(block.content)}
            </li>
          </ul>
        );
      case 'numbered_list_item':
        return (
          <ol key={index}>
            <li>
              {renderRichTextArray(block.content)}
            </li>
          </ol>
        );
      case 'quote': 
        return (
          <figure className="quote" key={index}>
            <blockquote>
              {renderRichTextArray(block.content)}
            </blockquote>
          </figure>
        );
      case 'callout': 
        return (
          <p className="callout" key={index}>
            {block.content.icon && <span className="icon">{block.content.icon}</span>}
            {block.content.richText.map((richText, textIndex) =>
              richText.link ? (
                <a href={richText.link} key={textIndex} target="_blank" rel="noopener noreferrer">
                  {renderTextWithAnnotations(richText.text, richText.annotations)}
                </a>
              ) : (
                <span key={textIndex}>{renderTextWithAnnotations(richText.text, richText.annotations)}</span>
              ))}
          </p>
        );
        case 'table':

          return (
            <table className="table" key={index}>
              <tbody>
                {block.content.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          );
        case 'column_list':
          return (
            <div className="row">
              {block.content.column_children.map((column_child, columnIndex) => (
                <div className="column" key={columnIndex}>
                  {Array.isArray(column_child) ? (
                    column_child.map((child, childIndex) => (
                      <div key={childIndex}>
                        {/* Check if child is an object with text */}
                        {typeof child === 'object' && child.text && (
                          <p>{child.text}</p>
                        )}
                        
                        {/* Check if child is a string (image URL) or an object with an image */}
                        {typeof child === 'string' ? (
                          <img src={child} alt={`Notion Image ${childIndex}`} style={{ alignSelf: "center" }} />
                        ) : (
                          child.image && (
                            <img src={child.image} alt={`Notion Image ${childIndex}`} style={{ alignSelf: "center" }} />
                          )
                        )}
                      </div>
                    ))
                  ) : (
                    typeof column_child === 'string' && (
                      <img src={column_child} alt={`Notion Image ${columnIndex}`} style={{ alignSelf: "center" }} />
                    )
                  )}
                </div>
              ))}
            </div>
          );
        


         
      case 'image':
        return <img src={block.content} alt={`Notion Image ${index}`} key={index} style={{ alignSelf: "center" }} />;
      case 'video':
        return <iframe width="250" src={block.content} key={index} style={{ alignSelf: "center" }} />;
      case 'pdf':
        return <embed maxwidth="900" height="400" type="application/pdf" src={block.content} key={index} />;
      case 'embed':
        return <iframe maxwidth="250" src={block.content} key={index} />;
      case 'divider':
        return <hr style={{ borderTop: "1px solid black", width: "100%" }} key={index} />;
      case 'child_page':
        return null;
      default:
        return <span key={index}>Unknown block type</span>;
    }
  };