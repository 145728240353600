import React from 'react';

const Verification = ({
  t,
  handleSubmit,
  handleBack,
  preLogin,
  mfaType,
  codeInputFields,
  errMsg,
  errRef,
  viewSecondary
}) => {
  return (
    <div className={"peili-content-wrapper authenticate-wrapper collapse " + viewSecondary.current}>
      <div className="authenticate-body">
        <div className='btn-holder'>
          <button className='button btn-orange' onClick={handleBack}>{t("BACK")}</button> 
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <h2>{t("Two Factor Authentication")}</h2>
          <div className='authentication-note'>
            <p>{t("A verification code has been sent via")} {t(mfaType)}. {t("This code will be valid for 15 minutes")}.</p>
          </div>
          <div className='inputs-holder'>{codeInputFields}</div>
          <button className='button mobile-adj'>{t('KIRJAUDU')}</button>
          <div className='resend-options'>
            <p>
              {t("It may take a minute to receive your code")}.<br />
              {t("Haven't received it")}? <button type="button" onClick={preLogin}>{t("Resend a new code")}.</button> 
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Verification;
