import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';


export default function InfoGraph() {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";


  const handleSupportClick = (event) => {
    event.preventDefault(); // Prevent the default navigation behavior
    navigate('/support', { replace: true });
  };

  return (
    <div className="info-graph-container">
        <h3>{t("PREPARE")}</h3>
        <div className='text-container'>
          <p><b>{t("Guide")}:</b></p>
            <p>{t("Start by reading the EFSim")}                
            <a href="" onClick={handleSupportClick}> {t("User Manual")}</a></p>
        
            <p><br></br><b>{t("Devices")}:</b></p>

            <p>{t("Use a desktop or laptop with stable internet. A mouse and headphones are recommended.")}</p>

            <p><br></br><b>{t("Environment and timing")}:</b></p>

            <p>{t("The test takes 20 minutes and must be done in one sitting. Make sure your child is alert and in a quiet, distraction-free space.")}</p>


            <h3><br></br>{t("TEST")}</h3>


            <p><br></br><b>{t("Audio")}:</b></p>

            <p>{t("Make sure your computer audio is working and adjust settings before the test. Headphones are recommended but not required.")}</p>

            <p><b>{t("Start Test")}:</b></p>

            <p>{t("When ready, click the START HERE button to begin.")}</p>

            <h3><br></br>{t("AFTER")}</h3>

            <p><b>{t("Results")}:</b></p>

            <p>{t("Results will be available in the portal after the test. Access them via the Results section.")}</p>


            <p><br></br><b>{t("Need Help?")}</b></p>
            <p>{t("For any issues, email us at")} <a href="mailto:support@efsimtest.com">support@efsimtest.com</a></p>
        </div>
    </div>
  )
}
