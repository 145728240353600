import React from "react";
import { ukcaIcon, eifuIcon, medDevicon, manufacturedIcon } from '../../icons';
import { ReactSVG } from "react-svg";

const Certification = () => {
    return (
        <>
            <div className="certification-container">
                <div className="certification-border shadow">
                    <div className="row justify-content-between mb-2">
                        <div className="col-4">
                         <div className="d-flex m-0">
                                <ReactSVG className="manufacturedIcon" src={manufacturedIcon} />
                                <ReactSVG className="medDevIcon" src={medDevicon} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="d-flex m-0">
                                <ReactSVG src={ukcaIcon} />
                                <ReactSVG src={eifuIcon} />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-12">
                            <div className="strong mb-1">
                                EFSim - Psychological assessment software
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="strong">
                               Manufacturer:
                            </div>
                        </div>
                        <div className="col-9 text-end">
                            <div>
                               Peili Vision Oy
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="strong">
                               Address:
                            </div>
                        </div>
                        <div className="col-9 text-end">
                            <div>
                               Kansankatu 53, 90100 Oulu, Finland
                            </div>
                        </div>

                        <div className="col-3 ">
                            <div className="strong">
                               Email:
                            </div>
                        </div>
                        <div className="col-9 text-end">
                            <div>
                                support@efsimtest.com
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="strong">
                               Version:
                            </div>
                        </div>
                        <div className="col-9 text-end">
                            <div>
                                2.4.0 - 2.0.0
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Certification;
