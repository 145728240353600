const LoadingRing = ({ smallLoader }) => {
    return (
        <div className={`lds-holder ${smallLoader ? 'smallLoader' : ''}`}>
            <div className="lds-ring">
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
    );
};

export default LoadingRing;
