import { useTranslation } from 'react-i18next';
import { ReactSVG } from "react-svg";
import { checkMarkIcon} from '../../../icons';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import { useState, useEffect, useRef } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { useNavigate, useLocation } from 'react-router-dom';

const PreStartTest = (props) => {
    const { t } = useTranslation();
    const cancel = () => {
        props.setPopupState('');
    }
    const { auth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const handleSupportClick = (event) => {
        event.preventDefault(); // Prevent the default navigation behavior
        navigate('/support', { replace: true });
      };

    let returnUrl = '';
    const currentURL = window.location.href;
    if (currentURL.includes('https://arvo-dev.azurewebsites.net/')) {
        returnUrl = 'https://arvo-dev.azurewebsites.net/';
    } else if (currentURL.includes('https://efsim-staging.azurewebsites.net/')) {
        returnUrl = 'https://efsim-staging.azurewebsites.net/';
    } else if (currentURL.includes('https://efsim.azurewebsites.net/')) {
        returnUrl = 'https://efsim.azurewebsites.net/';
    } else {
        returnUrl = window.location.href; // Fallback to environment variable
    }
    const sessionData = props.sessionData;
    const popupRef = useRef();
    useOnClickOutside(popupRef, () => props.setPopupState(''));
    const handleReady = async (id) => {
        try {
            const response = await axios.get(
                `/exercisesessions/${id}/PlayUrl`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`
                    }
                }
            );
            const playUrl = response.data;
            if (playUrl) {
                window.location.href = playUrl + "&returnUrl=" + returnUrl;
            } else {
                console.error('No URL found in the response');
            }
        } catch (error) {
            console.log('Error uploading token:', error?.response || error);
        }
    };
    const startTest = (id) => {
        //console.log(id)
        handleReady(id);
    }
    return (
        <section ref={popupRef} className="peili-popup-wrapper secondary-purple scrollable">
            <div className="content">
                <h4 className='mb-4 text-center text-secondary-purple'>
                    {t("Ready to Start?")}
                </h4>
                <div className='mb-5 text-center'>
                    {t("Before beginning, confirm these essentials")}:
                </div>
                <div className='d-flex align-items-center gap-3 mb-3'>
                    <div className=''>
                        <ReactSVG src={checkMarkIcon} />
                    </div>
                    <div className="items-to-check">
                        <span className='strong'>{t("Internet")}: </span> {t("Stable connection, computer charged/plugged in")}
                    </div>
                </div>
                <div className='d-flex align-items-center gap-3 mb-3'>
                    <div className=''>
                        <ReactSVG src={checkMarkIcon} />
                    </div>
                    <div className="items-to-check">
                    <span className='strong'>{t("Audio")}: </span> {t("Make sure the computer audio is working. If using headphones, put them on the child now.")}
                    </div>
                </div>
                <div className='d-flex align-items-center gap-3 mb-3'>
                    <div className=''>
                        <ReactSVG src={checkMarkIcon} />
                    </div>
                    <div className="items-to-check">
                    <span className='strong'>{t("Comfort")}: </span> {t("Child seated comfortably")}
                    </div>
                </div>
                <div className='d-flex align-items-center gap-3 mb-5'>
                    <div className=''>
                        <ReactSVG src={checkMarkIcon} />
                    </div>
                    <div className="items-to-check">
                    <span className='strong'>{t("Session")}: </span> {t("One uninterrupted 20-minute session")}
                    </div>
                </div>
                <div className='d-flex align-items-center gap-3 mb-5'>

                
                    <div className="items-to-check">
                        <span className='strong'>{t("Instructions")}: <br></br><br></br></span>
                        {t("Read this to the child: “You get to play a computer game guided by Laura the Dragon. Listen carefully to everything Laura says from start to finish before doing anything else. After Laura leaves, you can start completing the tasks as best as you can. We'll start with a practice session where Laura will show you how to play. The game will have many tasks, and there's a time limit, but don't worry if you can't remember or complete everything—just do your best. Laura will return to guide you, and the game will continue when you wait for a moment.”")}
                    </div>
                    
                </div>
                <div className=' text-center '>
                    <div className="items-to-check">
                        <span className='strong'>{t("NOTE")}: </span>
                        {t("If not ready, refer to the")} <a href="" onClick={handleSupportClick}>{t("Guide ")}</a> {t("for detailed instructions")}
                    </div>
                </div>
                <div className="buttons-wrapper d-flex gap-3 mt-5 justify-content-center">
                    <button className='button cancel-btn' onClick={() => cancel() }>{t("Cancel")}</button>
                    <button className='button btn-purple' onClick={(id) => startTest(sessionData) } >{t("Ready")}</button>
                </div>
            </div>
        </section>
    )
}
export default PreStartTest