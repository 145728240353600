import React, { useState } from 'react';

const Tooltip = ({ children, position = 'top', content, cert = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Functions to show and hide the tooltip
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={cert ? 'cert-wrapper' : 'tooltip-wrapper'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isHovered && (
        <div  className={`${cert ? 'cert-box' : 'tooltip-box'} ${position}`}>
          {Array.isArray(content) ? content.map((item, index) => <div key={index}>{item}</div>) : content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
