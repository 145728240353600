import { useEffect, useState, useRef, useCallback } from 'react';
import peiliLogo from './images/peili-logo.png';
import NavPanel from './navigation/NavPanel';
import Paths from './Paths';
import Login from './login/index';
import { AuthProvider } from './context/AuthProvider';
import "bootstrap/dist/css/bootstrap.min.css";
import Logout from './navigation/UserControl/Logout';
import useOnClickOutside from './hooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Certification from './components/certification';
import Tooltip from './components/util/Tooltip';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const containerRef = useRef(null);
  const [popup, setPopup] = useState('');
  const popupRef = useRef();
  const { t } = useTranslation();

  const location = useLocation();

  useOnClickOutside(popupRef, () => setPopup(''));

  const confirmLogout = () => {
    setPopup('logout-confirmation');
  };

  const cancel = () => {
    setPopup('');
  };

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  const checkScreenSize = () => {
    if (window.innerWidth <= 767) {
      setIsChecked(true);
      window.removeEventListener('resize', checkScreenSize);
    }
  };

  const handleNavigation = () => {
    if (window.innerWidth <= 767) {
      setIsChecked(true);
    }
  };

  useEffect(() => {
    handleNavigation(); // Initial check
  }, [location]);

  useEffect(() => {
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize(); // Initial check
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      setIsAtTop(containerRef.current.scrollTop === 0);
    }
  }, []);

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    setIsLoggedIn(authData && authData.accessToken != null);
  }, []);

  useEffect(() => {

    if (isLoggedIn && containerRef.current) {
      const scrollableElement = containerRef.current;
      scrollableElement.addEventListener('scroll', handleScroll);
      return () => {
        scrollableElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isLoggedIn, handleScroll]); 

  return (
    <AuthProvider>
      {isLoggedIn ? (
        <>
          {/* Logged In View */}
          <div
            ref={containerRef} 
            className={`container-fluid peili-header-container ${isAtTop ? 'at-top' : 'not-top'}`}>
            <div className="peili-header">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex">
                      <h1 className="efsim-logo stronger">EFSim</h1>
                      <div className="version-container">
                        <Tooltip cert={true} content={<Certification />}>
                          <span>v 2.4.0 - 2.0.0</span>
                      </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container max-vh-100">
              <div className={`peili-main-panel ${isChecked ? 'expand-nav' : ''}`}>
                <div className="left-panel">
                  <div className="efsim-nav shadow">
                    <NavPanel isChecked={isChecked} checkHandler={checkHandler} confirmLogout={confirmLogout} />
                  </div>
                </div>
                <div className="right-panel">
                  <div className="efsim-main shadow">
                    <Paths />
                  </div>
                </div>
              </div>
            </div>

          </div>

          {popup === 'logout-confirmation' && (
            <section ref={popupRef} className="peili-popup-wrapper red">
              <div className="delete-note">
                <h5 className='text-black text-center'>{t("Are you sure you want to logout?")}</h5>
              </div>
              <div className="col-md-12">
                <div className="buttons-wrapper d-flex gap-3 mt-5 justify-content-center">
                  <button className="button cancel-btn" onClick={cancel}>{t("Cancel")}</button>
                  <Logout></Logout>
                </div>
              </div>
            </section>
          )}
        </>
      ) : (
        <>
          {/* Not Logged In View */}
          <div className="peili-main-container">
            <div className="peili-main-bg"></div>
            <div className="container max-vh-100">
              <div className="row">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div className="nav-holder">
                    <div className="brand-logo">
                      <h1 className="landing-brand stronger">EFSim</h1>
                      <div className="peili-logo-holder mt-3">
                        <div className="main-logo-holder">
                          <img alt="peili-icon" src={peiliLogo} className="peili-icon" />
                        </div>
                        <h3>Peili Vision</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <div className="right-panel">
                    <div className="main-content">
                      <Login />
                    </div>
                  </div>
                  <div className="cert-floater">
                      <Certification />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AuthProvider>
  );
}

export default App;
