import axios from '../api/axios';
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();

    const refresh = async () => {
        try {
            const storedAuth = JSON.parse(localStorage.getItem('auth'));
            const refreshToken = auth?.refreshToken || storedAuth?.refreshToken;

            if (!refreshToken) {
                throw new Error("No refresh token found");
            }

            const response = await axios.post('/auth/refresh', 
                { 'refreshToken': refreshToken }, 
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const newAccessToken = response.data.accessToken;
            const newRefreshToken = response.data.refreshToken;

            // Update auth state and localStorage with new tokens
            setAuth(prevAuth => ({
                ...prevAuth,
                accessToken: newAccessToken,
                refreshToken: newRefreshToken
            }));

            localStorage.setItem('auth', JSON.stringify({
                ...storedAuth,
                accessToken: newAccessToken,
                refreshToken: newRefreshToken
            }));

            return newAccessToken;

        } catch (error) {
            console.error("Token refresh failed:", error);
            localStorage.removeItem('auth');
            window.location.reload();
            throw error;
        }
    };

    return refresh;
};

export default useRefreshToken;
