import { useRef, useState, useCallback } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { backBtn } from '../../../../icons/index.js';
import { ReactSVG } from "react-svg";
import CustomDatePicker from "../../../../components/util/CustomDatePicker.js";
import CustomInput from "../../../../components/util/CustomInput.js";
import CustomSelect from "../../../../components/util/CustomSelect.js";
import { validateEmail, validatePhoneNumber, isEng } from "../../../../components/util/utils.js";
import { PhoneInput } from 'react-international-phone';
import { Link } from 'react-router-dom';
import { languageObject, genderObject, handednessObject, relationObject } from '../Objects.js';
import LoadingRing from '../../../../components/LoadingRing/index.js';
import AddExerciseSession from '../components/AddExerciseSession';
import 'react-international-phone/style.css';
 
const AddPatientForm = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const PUT_PATIENT_URL = '/Patients/';
    const errRef = useRef();
    const initialDate = new Date();
    initialDate.setFullYear(initialDate.getFullYear() - 5);
    const initialDateISO = initialDate.toISOString().split('T')[0] + 'T00:00:00.000Z';

    const [patientName, setPatientName] = useState('');
    const [patientLastName, setPatientLastName] = useState('');
    const [patientDOB, setPatientDOB] = useState(initialDateISO);
    const [patientGender, setPatientGender] = useState('male');
    const [patientLang, setPatientLang] = useState('en-GB');
    const [patientHandedness, setPatientHandedness] = useState('right');
    const [patientEmail, setPatientEmail] = useState('');
    const [patientContact, setPatientContact] = useState('');
    const [patientGuardianName, setPatientGuardianName] = useState('');
    const [patientGuardianLastName, setPatientGuardianLastName] = useState('');
    const [patientGuardianStatus, setPatientGuardianStatus] = useState('parent');
    const [patientComment, setPatientComment] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [validContact, setValidContact] = useState(true);

    const [isGuardianSwitchChecked, setIsGuardianSwitchChecked] = useState(true);

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setIsGuardianSwitchChecked(isChecked);
    };

    const resetForm = () => {
        setPatientName('');
        setPatientLastName('');
        setPatientDOB(initialDateISO);
        setPatientGender('Male');
        setPatientLang('en-US');
        setPatientHandedness('right');
        setPatientEmail('');
        setPatientContact('');
        setPatientGuardianName('');
        setPatientGuardianLastName('');
        setPatientGuardianStatus('parent');
    };

    const handleAddExerciseSession = useCallback(async (exerciseSessionId) => {
        try {
            return await AddExerciseSession(axiosPrivate, exerciseSessionId);
        } catch (err) {
            handleError(err);
            throw err;
        }
    }, [axiosPrivate]);
    
    const addPatient = async () => {
        try {
            const response = await axiosPrivate.post(
                PUT_PATIENT_URL,
                JSON.stringify({
                    firstName: patientName,
                    lastName: patientLastName,
                    nickname: patientName,
                    language: patientLang,
                    handedness: patientHandedness,
                    gender: patientGender,
                    birthDate: patientDOB,
                    otherComments: patientComment,
                    userInvites: isGuardianSwitchChecked
                        ? [
                            {
                                email: patientEmail,
                                firstName: patientGuardianName,
                                lastName: patientGuardianLastName,
                                language: patientLang,
                                phoneNumber: patientContact,
                                associationType: patientGuardianStatus,
                            },
                        ]
                    : [], 
                })
            );
            return response.data.id;
        } catch (err) {
            handleError(err);
            throw err;
        }
    };

    const handleError = (err) => {
        if (!err?.response) {
            setErrMsg(t('No server response'));
        } else if (err.response?.status === 400) {
            setErrMsg(err.response?.data);
        } else if (err.response?.status === 401) {
            setErrMsg(t('401 Unauthorized'));
        } else {
            setErrMsg(t('Request failed'));
        }
        errRef.current.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrMsg('');

        if ([patientName, patientLastName, patientDOB].includes('')) {
            setErrMsg(t('Please complete the details'));
            return;
        } 

        if(isGuardianSwitchChecked){
            setValidContact(true);
            setValidEmail(true);

            if ([patientContact, patientEmail, patientGuardianName, patientGuardianLastName, patientGuardianStatus].includes('')) {
                setErrMsg(t('Please complete the details'));
                return;
            }else if (!validateEmail(patientEmail)) {
                setErrMsg(t('Invalid email'));
                setValidEmail(false);
                return;
            } else if (!validatePhoneNumber(patientContact)) {
                setErrMsg(t('Invalid phone number'));
                setValidContact(false);
                return;
            }
        }

        setIsLoading(true);
        
        try {
            const patientId = await addPatient();
            await handleAddExerciseSession(patientId);
            resetForm();
            if(isGuardianSwitchChecked){
                localStorage.setItem("successNotification", true);
            }
            navigate(`/view-patient?id=${patientId}`);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
         {isLoading ? (
            <LoadingRing />
        ) : (
            <div className="peili-main-wrapper">
            {isLoading && <LoadingRing/> }
            <div className="peili-views-wrapper">
                <h1>{t("Add Patient")}</h1>
                <div className="peili-border"></div>
                <section>
                    <div className="mb-4">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h5 className="text-blue">{t("Patient Details")}</h5>
                            </div>
                            <div className="col-md-6 text-end">
                                <Link className="button btn-yellow" to={'/'}>
                                    <ReactSVG src={backBtn} />
                                    <span>{t("BACK")}</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <section className="peili-form-wrapper">
                            <form id="Add-user-form" className="add-patient-form" onSubmit={handleSubmit} autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6 item mb-5">
                                        <CustomInput
                                            attr={'firstName'}
                                            label={t('First Name')}
                                            placeholder={t("e.g. John")}
                                            value={patientName}
                                            setter={setPatientName}
                                            hasError={errMsg && patientName === ''}
                                        />
                                    </div>
                                    <div className="col-md-6 item mb-5">
                                        <CustomInput
                                            attr={'lastName'}
                                            label={t('Last Name')}
                                            placeholder={t("e.g. Smith")}
                                            value={patientLastName}
                                            setter={setPatientLastName}
                                            hasError={errMsg && patientLastName === ''}
                                        />
                                    </div>
                                    <div className="col-md-3 item mb-5">
                                        <CustomDatePicker
                                            attr={'patientDOB'} 
                                            label={t('Date of Birth')} 
                                            selectedDate={patientDOB} 
                                            onDateChange={setPatientDOB}
                                            language={isEng() ? 'en' : 'fi'}
                                        />
                                    </div>

                                    <div className="item col-md-3 mb-5">
                                        <CustomSelect
                                            attr={'languageCode'}
                                            label={t("LANGUAGE TEST")}
                                            options={languageObject}
                                            value={patientLang}
                                            setter={setPatientLang}
                                        >
                                        </CustomSelect>
                                    </div>

                                    <div className="item col-md-3 mb-5">
                                        <CustomSelect
                                            attr={'gender'}
                                            label={t("Gender")}
                                            options={genderObject}
                                            value={patientGender}
                                            setter={setPatientGender}
                                        >
                                        </CustomSelect>
                                    </div>

                                    <div className="item col-md-3 mb-5">
                                        <CustomSelect
                                            attr={'handedness'}
                                            label={t("HANDEDNESS")}
                                            options={handednessObject}
                                            value={patientHandedness}
                                            setter={setPatientHandedness}
                                        >
                                        </CustomSelect>
                                    </div>
                                    <div className="col-md-12 item mb-4">
                                        <label htmlFor="others">{t("Other Information")}</label>
                                        <textarea rows="4" 
                                            type="text"
                                            id="others"
                                            value={patientComment}
                                            onChange={(e) => setPatientComment(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <div className="d-flex">
                                            <h5 className="mt-4  mb-4 text-blue">{t("Guardian Details")}</h5>
                                            <div className="form-check parent-credential-switch form-switch align-self-center">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={isGuardianSwitchChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="col-md-6 item mb-5">
                                        <CustomInput
                                            attr={'guardianFirstName'}
                                            label={t('First Name')}
                                            placeholder={t("e.g John")}
                                            value={patientGuardianName}
                                            setter={setPatientGuardianName}
                                            hasError={errMsg && patientGuardianName === ''}
                                            disable={!isGuardianSwitchChecked}
                                        ></CustomInput>
                                    </div>

                                    <div className="col-md-6 item mb-5">
                                        <CustomInput
                                            attr={'guardianLastName'}
                                            label={t('Last Name')}
                                            placeholder={t("e.g. Smith")}
                                            value={patientGuardianLastName}
                                            setter={setPatientGuardianLastName}
                                            hasError={errMsg && patientGuardianLastName === ''}
                                            disable={!isGuardianSwitchChecked}
                                        ></CustomInput>
                                    </div>

                                    <div className="item col-md-4 mb-5">
                                        <CustomSelect
                                            attr={'guardianStatus'}
                                            label={t("Relation")}
                                            options={relationObject}
                                            value={patientGuardianStatus}
                                            setter={setPatientGuardianStatus}
                                            disable={!isGuardianSwitchChecked}
                                        >
                                        </CustomSelect>
                                    </div>

                                    <div className="col-md-4 item mb-5">
                                        <CustomInput
                                            attr={'email'}
                                            label={t('Email')}
                                            placeholder={t("e.g. john@efsim.com")}
                                            value={patientEmail}
                                            setter={setPatientEmail}
                                            hasError={errMsg && patientEmail === '' || !validEmail}
                                            disable={!isGuardianSwitchChecked}
                                        ></CustomInput>
                                    </div>

                                    <div className="col-md-4 item mb-5">
                                        <div className={`phone-input ${!isGuardianSwitchChecked ? 'disable' : ''}`}>
                                            <label htmlFor="phone">{t("Phone")}</label>
                                            <PhoneInput
                                                defaultCountry="gb"
                                                value={patientContact}
                                                onChange={setPatientContact}
                                                className={errMsg && patientContact === '' || !validContact ? 'has-error' : ''}
                                            />
                                        </div>
                                    </div>                                
                                </div>

                                <div className="buttons-wrapper">
                                    <button className="button green-btn" type="submit">
                                        {t("SAVE")}
                                    </button>
                                </div>

                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                    {errMsg}
                                </p>
                            </form>
                        </section>
                    </div>
                </section>
            </div>
        </div>
        )}
        </>
        
    );
};

export default AddPatientForm;
