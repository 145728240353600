import { Navigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import Login from './login/index';

export const LoginOrRedirect = () => {
  const { auth } = useAuth();
  
  if (auth.accessToken && auth.refreshToken) {
    return <Navigate to="/" />;
  }

  return <Login />;

};


export default LoginOrRedirect