import React, { useEffect, useState } from 'react';
import ProfessionalUser from './professionalUser';
import ParentUser from './parentUser';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import LoadingRing from '../../components/LoadingRing';

const Home = () => {
    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const [view, setView] = useState(<ParentUser />);
    const [viewLoaded, setViewLoaded] = useState(false);  
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        returnView(auth);
    }, [auth]);

    const returnView = (auth) => {
        if (viewLoaded === false) {
            if (auth.userType === 'MedicalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'MedicalUser') {
                setView(<ProfessionalUser />);
                setViewLoaded(true);
            } else if (auth.userType === 'PersonalUser' || JSON.parse(localStorage.getItem('auth')).userType === 'PersonalUser') {
                setView(<ParentUser />);
                setViewLoaded(true);
            }
        }
    }

    const getUserData = async () => {
        setLoading(true);

        try {
            const response = await axiosPrivate.get('/user');
            const data = response.data;

            let id = data.id;
            let name = data.firstName + ' ' + data.lastName;
            let email = data.email;
            let organisation = data.organisation;
            let language = data.language;
            let organisationId = data.organisation?.organisationId;
            let welcomeOnboardingShownDate = data.welcomeOnboardingShownDate;
            let resultsOnboardingShownDate = data.resultsOnboardingShownDate;
            let userType = data.userType;
            let roles = data.roles;

            setAuth(prevAuth => ({
                ...prevAuth,
                id,
                name,
                email,
                organisation,
                language,
                organisationId,
                welcomeOnboardingShownDate,
                resultsOnboardingShownDate,
                userType,
                roles
            }));

            localStorage.setItem('auth', JSON.stringify({
                ...JSON.parse(localStorage.getItem('auth') || '{}'),
                id,
                name,
                email,
                roles,
                language,
                organisation,
                organisationId,
                welcomeOnboardingShownDate,
                resultsOnboardingShownDate,
                userType
            }));

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false); 
        }
    }

    useEffect(() => {
        if (!viewLoaded) {
            getUserData();
        }
    }, [viewLoaded]);

    if (loading) {
        return <LoadingRing></LoadingRing>; 
    }

    return (
        <>
            {view}
        </>
    );
}

export default Home;
