import React from 'react';
import { useTranslation } from 'react-i18next';

const SelectField = ({ attr, label, options, value, setter, disable }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={disable ? 'disable' : ''}>
                <label htmlFor={attr}>{label}</label>
                <select
                    className="peili-select-field"
                    id={attr}
                    value={value}
                    onChange={(e) => setter(e.target.value)}
                >
                    {Object.entries(options).map(([key, value], i) => (
                        <option key={`option-${i}`} value={value}>
                            {t(key)} 
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default SelectField;