import { useTranslation } from 'react-i18next';
import React, { useMemo, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import DownloadParentReport from '../../components/DownloadParentReport';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { ReactSVG } from "react-svg";
import { downloadIcon, playIcon } from '../../../../../icons/index.js';
import { convertToFiDateTimeFormat, convertToEnDateTimeFormat, isEng } from "../../../../../components/util/utils.js";

const PendingSessionTable = (props) => {
    const { t } = useTranslation();
    const patientSessionList = props.patientSessions || [];
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const pendingSessions = useMemo(() => {
        return patientSessionList.filter(el => !el?.finalized);
    }, [patientSessionList]);

    const completedSessions = useMemo(() => {
        return patientSessionList.filter(el => el?.finalized);
    }, [patientSessionList]);

    const handleDownload = useCallback((exerciseSessionId) => {
        DownloadParentReport(axiosPrivate, exerciseSessionId);
    }, [axiosPrivate]);

    const viewExerciseSession = (sessionId) => {
        navigate(`/view-patient/session-details?id=${props.patientId}&sessionId=${sessionId}`);
    };

    return (
        <section className='mb-4 mt-4'>
            <h6 className='text-orange'>{t("Pending Tests")}</h6>
            <div className='table-holder mb-5'>
                <table className="peili-table">
                    <thead>
                        <tr>
                            <th className='table-header'>{t("Date Created")}</th>
                            <th className='table-header'></th>
                        </tr>  
                    </thead>
                    {pendingSessions.length > 0 ? (
                        <tbody>
                            {pendingSessions.map((el, i) => (
                                <tr key={el?.exerciseSessionId || i}>
                                    <td>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(el.createdDate + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return 'Invalid date';
                                            }
                                        })()}
                                    </td>

                                    <td className='d-flex justify-content-end gap-3'>
                                        <button 
                                            className={"button btn-purple"}  
                                            onClick={() => props.openPreStartTest(el?.id)}>
                                            <ReactSVG src={playIcon} />
                                            {t("Start the Test")}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="2">{t("NO SESSION TO DISPLAY")}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>

            <h6 className='text-green mt-4'>{t("Completed Tests")}</h6>
            <div className='table-holder'>
                <table className="peili-table">
                    <thead>
                        <tr>
                            <th className='table-header'>{t("Date Completed")}</th>
                            <th className='table-header'></th>
                        </tr>  
                    </thead>
                    {completedSessions.length > 0 ? (
                        <tbody>
                            {completedSessions.map((el, i) => (
                                <tr key={el?.exerciseSessionId || i}>
                                    <td>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(el.testEndTime + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return 'Invalid date';
                                            }
                                        })()}
                                    </td>
                                    
                                    <td className='d-flex justify-content-end gap-3'>
                                        <button 
                                            className={"button btn-green"}  
                                            onClick={() => viewExerciseSession(el?.id)}>
                                            {t("View Results")}
                                        </button>
                                        <button 
                                            className={"button btn-white-green"}  
                                            onClick={() => handleDownload(el?.id)}>
                                            <ReactSVG src={downloadIcon} />
                                            {t("Parent Report")}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="2">{t("No completed tests to display")}</td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
        </section>
    );
}

export default PendingSessionTable;
