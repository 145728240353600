import { parsePhoneNumberFromString } from "libphonenumber-js";
// Function to calculate age based on a date string
export const calculateAge = (dateString) => {
    const birthDate = new Date(dateString);
    const today = new Date();
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
  
    // If current month is before the birth month or it's the birth month and current day is before birth day, subtract 1 from age
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
  
    return age;
};

// Function to convert an ISO8601 date string to a more readable format
export const convertToEnDateFormat = (isoString) => {
    const date = new Date(isoString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('UTC', options);
};

export const convertToEnDateTimeFormat = (isoDateString) => {
  if (!isoDateString) return ''; // Return empty string for invalid input

  try {
      const date = new Date(isoDateString);

      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${formattedDate} ${hours}:${minutes}:${seconds}`; // Format as "Month day, year hh:mm:ss"
  } catch (error) {
      console.error('Invalid date format:', isoDateString);
      return '';
  }
};

export const convertToFiDateFormat = (isoString) => {
  if (!isoString) return '';

  try {
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
  } catch (error) {
      console.error('Invalid date format:', isoString);
      return '';
  }
};

export const convertToFiDateTimeFormat = (isoDateString) => {
  if (!isoDateString) return ''; // Return empty string for invalid input

  try {
      const date = new Date(isoDateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`; // Format as mm/dd/yyyy hh:mm:ss
  } catch (error) {
      console.error('Invalid date format:', isoDateString);
      return '';
  }
};

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function validatePhoneNumber(phone) {
  const phoneNumber = parsePhoneNumberFromString(phone);
  if (phoneNumber) {
    if (phoneNumber.isValid()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
} 

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB'); // 'en-GB' locale formats the date as DD/MM/YYYY
};

export const toCamelCase = (str) => {
  if (!str) return '';
  return str
      .toLowerCase()
      .replace(/(^|[\s-_])\w/g, match => match.trim().toUpperCase())
      .replace(/[\s-_]/g, '');
};

export const isEng = () => {
  const savedLanguage = localStorage.getItem('i18nextLng');
  return savedLanguage === 'en';
};