import React, { useState, useEffect } from 'react';
import './regular_page.css';
import ChildPage from './ChildPage.js';
import {renderBlock} from '../../components/knowledgebase/notion_blocks_handlers.js';
import LoadingRing from '../../components/LoadingRing/index.js';

const SubPage = ({ blockId }) => {
  const [fetchedData, setFetchedData] = useState(null);
  const [childPageData, setChildPageData] = useState(null); // State to hold child page content

  const [loading, setLoading] = useState(false);

  // Fetch parent page data
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await fetch(`https://efsim-notion.azurewebsites.net/fetchDataFromNotion/${blockId}`);
        const data = await response.json();
        setFetchedData(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    if (blockId) {
      setLoading(true);
      fetchData();
    }
  }, [blockId]); // Fetch parent page data when blockId changes
 
  // Fetch child page data when needed
  useEffect(() => {
    const fetchChildPageData = async (childId) => {
      try {
        
        const response = await fetch(`https://efsim-notion.azurewebsites.net/fetchDataFromNotion/${childId}`);
        const data = await response.json();
        setLoading(false);
        setChildPageData(data); // Set the fetched child page content to state
      } catch (error) {
        console.error("Error fetching child page data:", error);
      }
    };

    // If all blocks are child pages, fetch the first child page
    if (fetchedData && fetchedData.processedContent.every(block => block.type === 'child_page')) {
      const firstChildPage = fetchedData.processedContent[0];
      fetchChildPageData(firstChildPage.id); // Fetch the first child page
    }
  }, [fetchedData]); // Only run this effect when fetchedData is loaded

  const renderContent = () => {
    if (!fetchedData || !fetchedData.processedContent) return null;

    // Check if all blocks are child pages
    const allChildPages = fetchedData.processedContent.every(block => block.type === 'child_page');

    if (allChildPages && childPageData) {
      // If all blocks are child pages, render the first child page's content
      return (
        <div>
          <h2>{childPageData.title || "Child Page Title"}</h2>
          {childPageData.processedContent.map((childBlock, childBlockIndex) => {
            return renderBlock(childBlock, childBlockIndex);
          })}
        </div>
      );
    }

    // Otherwise, render the mixed blocks normally
    return fetchedData.processedContent.map((block, index) => {
      if (block.type !== 'child_page') {
        return renderBlock(block, index); // Render only non-child_page blocks
      }
      return null; // Skip rendering child_page blocks in this case
    });
  };



  return (
    
    <div className="sub-page">
      <>
        {loading ? (
          <LoadingRing />
        ) : (
          fetchedData ? (
            <div className="container_kb">
              <h1 className="main_h1">{fetchedData.title || "No title available"}</h1>
              <div className="page">{renderContent()}</div>
            </div>
          ) : (
            <p>Loading...</p>
          )
        )}
      </>

    </div>
  );
};

export default SubPage;
