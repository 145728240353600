import { React, useState, useEffect, useCallback } from 'react';
import ScatterPlot from "../../../../components/graph/ScatterPlot.js";
import { useTranslation } from 'react-i18next';
import ResultInterpretation from "./ResultInterpretation.js";
import { useLocation, Link } from 'react-router-dom';
import Tooltip from "../../../../components/util/Tooltip.js";
import { ReactSVG } from "react-svg";
import { circleQuestion, backBtn, downloadIcon } from '../../../../icons/index.js';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import LoadingRing from '../../../../components/LoadingRing/index.js';
import DownloadParentReport from '../components/DownloadParentReport';
import { convertToFiDateTimeFormat, convertToEnDateTimeFormat, isEng } from "../../../../components/util/utils.js";


const SessionDetails = () => {
    const { t } = useTranslation();
    const [popup, setPopup] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get('id');
    const sessionId = searchParams.get('sessionId');
    const [errMsg, setErrMsg] = useState('');
    const [exerciseSessionData, setExerciseSessionData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [hoveredTooltip, setHoveredTooltip] = useState(null);

    const handleDownload = useCallback((exerciseSessionId) => {
        DownloadParentReport(axiosPrivate, exerciseSessionId);
    }, [axiosPrivate]);

    const loadSessionDetails = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axiosPrivate.get(`/exercisesessions/${sessionId}`);
            setExerciseSessionData(response.data); 
        } catch (err) {
            setErrMsg('Failed to fetch session details');
        } finally {
            setLoading(false); 
        }
    }, [axiosPrivate, sessionId]);

    useEffect(() => {
        setPopup('');
        loadSessionDetails(); 
    }, [loadSessionDetails]);

    if (loading) {
        return <LoadingRing />;
    }

    if (errMsg) {
        return <div>{errMsg}</div>; 
    }

    let npiResults = exerciseSessionData?.npiResults || [];
    let npiNormData = exerciseSessionData?.npiNormData || [];


    let DailyLivingTotalScore1Measure = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingTotalScore1Measure") || {};
    let DailyLivingTaskEfficacy1Measure = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingTaskEfficacy1Measure") || {};
    let DailyLivingNavigationEfficacyMeasure = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNavigationEfficacyMeasure") || {};
    let DailyLivingActions3Measure = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingActions3Measure") || {};
    let DailyLivingNumTimesLookedAtWatch = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNumTimesLookedAtWatch") || {};
    let DailyLivingNumCorrectTimedTasks = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNumCorrectTimedTasks") || {};
    let DailyLivingNumCorrectInterruptionTasks = npiResults.find((result) => result.associatedStandardizedMeasureId === "DailyLivingNumCorrectInterruptionTasks") || {};

    let DailyLivingTotalScore1MeasureData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingTotalScore1Measure") || {};
    let DailyLivingTaskEfficacy1MeasureData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingTaskEfficacy1Measure") || {};
    let DailyLivingNavigationEfficacyMeasureData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingNavigationEfficacyMeasure") || {};
    let DailyLivingActions3MeasureData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingActions3Measure") || {};
    let DailyLivingNumTimesLookedAtWatchData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingNumTimesLookedAtWatch") || {};
    let DailyLivingNumCorrectTimedTasksData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingNumCorrectTimedTasks") || {};
    let DailyLivingNumCorrectInterruptionTasksData = npiNormData.find((values) => values.associatedMeasureId === "DailyLivingNumCorrectInterruptionTasks") || {};

    const calculateZScore = (measure, measureData) => {
        if (measure && measureData && measure.result !== undefined && measureData.mean !== undefined && measureData.standardDeviation !== undefined) {
            return (measure.result - measureData.mean) / measureData.standardDeviation;
        }
        return 0;
    };

    const DailyLivingTotalScore1MeasureZ = calculateZScore(DailyLivingTotalScore1Measure, DailyLivingTotalScore1MeasureData);
    const DailyLivingTaskEfficacy1MeasureZ = calculateZScore(DailyLivingTaskEfficacy1Measure, DailyLivingTaskEfficacy1MeasureData);
    const DailyLivingNavigationEfficacyMeasureZ = calculateZScore(DailyLivingNavigationEfficacyMeasure, DailyLivingNavigationEfficacyMeasureData);
    const DailyLivingActions3MeasureZ = calculateZScore(DailyLivingActions3Measure, DailyLivingActions3MeasureData);
    const DailyLivingNumTimesLookedAtWatchZ = calculateZScore(DailyLivingNumTimesLookedAtWatch, DailyLivingNumTimesLookedAtWatchData);
    const DailyLivingNumCorrectTimedTasksZ = calculateZScore(DailyLivingNumCorrectTimedTasks, DailyLivingNumCorrectTimedTasksData);
    const DailyLivingNumCorrectInterruptionTasksZ = calculateZScore(DailyLivingNumCorrectInterruptionTasks, DailyLivingNumCorrectInterruptionTasksData);

    const truncateDecimal = (number) => {
        const factor = Math.pow(10, 3);
        return Math.floor(number * factor) / factor;
    };

    const convertDot = (number) => {
        if (number > 3) {
            return 3;
        } else if (number < -3) {
            return -3;
        } else if (number === 0) {
            return 0;
        } else {
            return number;
        }
    };

    const description = (z) => {
        if (z <= -2) {
            return t("Very Low");
        } else if (z >= -2 && z <= -1.32) {
            return t("Significantly under average");
        } else if (z >= -1.33 && z <= -0.66) {
            return t("Slightly under average");
        } else if (z >= -0.67 && z <= 0.65) {
            return t("Average");
        } else if (z >= 0.66 && z <= 1.31) {
            return t("Slightly over average");
        } else if (z >= 1.32 && z <= 1.98) {
            return t("Significantly over average");
        } else if (z >= 1.99) {
            return t("Very high");
        }
    };

    const data = [
        {
            "x": convertDot(DailyLivingTotalScore1MeasureZ),
            "y": 3.5,
            "z": truncateDecimal(DailyLivingTotalScore1MeasureZ),
        },
        {
            "x": convertDot(DailyLivingTaskEfficacy1MeasureZ),
            "y": 2.5,
            "z": truncateDecimal(DailyLivingTaskEfficacy1MeasureZ)
        },
        {
            "x": convertDot(DailyLivingNavigationEfficacyMeasureZ),
            "y": 1.5,
            "z": truncateDecimal(DailyLivingNavigationEfficacyMeasureZ)
        },
    ];

    const data1 = [
        {
            "x": convertDot(DailyLivingActions3MeasureZ),
            "y": 4.5,
            "z": truncateDecimal(DailyLivingActions3MeasureZ),
        },
        {
            "x": convertDot(DailyLivingNumTimesLookedAtWatchZ),
            "y": 3.5,
            "z": truncateDecimal(DailyLivingNumTimesLookedAtWatchZ),
        },
        {
            "x": convertDot(DailyLivingNumCorrectTimedTasksZ),
            "y": 2.5,
            "z": truncateDecimal(DailyLivingNumCorrectTimedTasksZ),
        },
        {
            "x": convertDot(DailyLivingNumCorrectInterruptionTasksZ),
            "y": 1.5,
            "z": truncateDecimal(DailyLivingNumCorrectInterruptionTasksZ),
        }
    ];

    const openResultInterpretation = () => {
        setPopup('result-interpretation');
    };

    console.log("session data", exerciseSessionData)

    const patientDetails = exerciseSessionData.patient;

    return (
        <div className="peili-main-wrapper">
            <div className="peili-views-wrapper">
                <h1>{t("Test Results")}</h1>
                <div className="peili-border"></div>
              
                <div className="mb-1">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <h4 className="text-blue">{patientDetails.firstName} {patientDetails.lastName}</h4>
                        </div>
                        <div className="col-md-6 text-end">
                            <Link className="button btn-yellow"  to={`/view-patient?id=${patientId}`}>
                                <ReactSVG src={backBtn} />
                                <span>{t("BACK")}</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <section className='session-details'>

                    {
                        (popup === 'result-interpretation') ?
                            <ResultInterpretation setPopupState={setPopup}></ResultInterpretation>
                            :
                            <></>
                    }
                    <div className="row">
                        <div className="col-md-6">
                            <div className='contact-details'>
                                <div className='row '>
                                    <div className='col-md-4 label'>{t("Test start")}:</div>
                                    <div className='col-md-8'>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(exerciseSessionData?.testStartTime + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return 'Invalid date';
                                            }
                                        })()}
                                    </div>
                                    <div className='col-md-4 label'>{t("Test completed")}:</div>
                                    <div className='col-md-8'>
                                        {(() => {
                                            try {
                                                const dateISO = new Date(exerciseSessionData?.testEndTime + 'Z').toISOString();
                                                return isEng() ? convertToEnDateTimeFormat(dateISO) : convertToFiDateTimeFormat(dateISO);
                                            } catch {
                                                return 'Invalid date';
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button 
                                className={"button btn-white-green"}  
                                onClick={() => handleDownload(sessionId)}>
                                <ReactSVG src={downloadIcon} />
                                {t("Parent Report")}
                            </button>
                        </div>
                    </div>

                    <div className='session-results mt-3'>
                        <div className='session-results-left  mt-2'>
                            <h6>{t("RESULT")}</h6>
                            <div className='session-result-table'>
                                <table className="peili-table session-table">
                                    <thead>
                                        <tr>
                                            <th className='table-header' >{t("Measure")} </th>
                                            <th className='table-header' >{t("RP")} </th>
                                            <th className='table-header' >{t("Z")} </th>
                                            <th className='table-header' >{t("Description")} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("Total Score")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("Number of subtasks performed correctly and on time (+/- 10 seconds from the given time)")}</div>,
                                                <div key="2">-{t("General measure of prospective memory in multitasking situations")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingTotalScore1Measure?.result)} / {(DailyLivingTotalScore1MeasureData?.maxValue)}</td>
                                            <td>{truncateDecimal(DailyLivingTotalScore1MeasureZ)}</td>
                                            <td>{description(DailyLivingTotalScore1MeasureZ)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("Task efficacy")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("The ratio of essential actions to all actions, excluding movement")}</div>,
                                                <div key="2">-{t("Measure of attention and executive functioning skills in complex situations")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingTaskEfficacy1Measure?.result)}</td>
                                            <td>{truncateDecimal(DailyLivingTaskEfficacy1MeasureZ)}</td>
                                            <td>{description(DailyLivingTaskEfficacy1MeasureZ)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("Navigation efficacy")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("The ratio of correctly performed subtasks to the amount of movement")}</div>,
                                                <div key="2">-{t("Measure of the planning of movement")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingNavigationEfficacyMeasure?.result)}</td>
                                            <td>{truncateDecimal(DailyLivingNavigationEfficacyMeasureZ)}</td>
                                            <td>{description(DailyLivingNavigationEfficacyMeasureZ)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="peili-table session-table second-table">
                                    <thead>
                                        <tr>
                                            <th className='table-header' ></th>
                                            <th className='table-header' ></th>
                                            <th className='table-header' ></th>
                                            <th className='table-header' ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("Total actions")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("Total number of actions, including movement")}</div>,
                                                <div key="2">-{t("Measure of motor activity and impulsive behavior")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingActions3Measure?.result)}</td>
                                            <td>{truncateDecimal(DailyLivingActions3MeasureZ)}</td>
                                            <td>{description(DailyLivingActions3MeasureZ)}</td>
                                        </tr>
                                        <tr>
                                        <td>{t("Number of clock checks")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("How many times the user checked the time on the clock during the test")}</div>,
                                                <div key="2">-{t("Measure of time management and monitoring the passage of time")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingNumTimesLookedAtWatch?.result)}</td>
                                            <td>{truncateDecimal(DailyLivingNumTimesLookedAtWatchZ)}</td>
                                            <td>{description(DailyLivingNumTimesLookedAtWatchZ)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("Time-based subtasks")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("Number of timed subtasks performed correctly and on time (+/- 10 seconds from the given time)")}</div>,
                                                <div key="2">-{t("Measures the ability to perform tasks requiring temporal precision in prospective memory")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingNumCorrectTimedTasks?.result)}</td>
                                            <td>{truncateDecimal(DailyLivingNumCorrectTimedTasksZ)}</td>
                                            <td>{description(DailyLivingNumCorrectTimedTasksZ)}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("Event-based prospective")} <br></br> {t("memory subtasks")}
                                            <Tooltip
                                                position="right"
                                                content={[
                                                <div key="1">-{t("Number of subtasks performed correctly and on time (within 10 seconds), to be completed after an external cue")}</div>,
                                                <div key="2">-{t("Ability to respond to external memory cues")}</div>,
                                                ]}
                                            >
                                                <span><ReactSVG src={circleQuestion} /> </span>
                                            </Tooltip>
                                            </td>
                                            <td>{truncateDecimal(DailyLivingNumCorrectInterruptionTasks?.result)}</td>
                                            <td>{truncateDecimal(DailyLivingNumCorrectInterruptionTasksZ)}</td>
                                            <td>{description(DailyLivingNumCorrectInterruptionTasksZ)}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className='session-results-right'>
                            <ScatterPlot height={180} margin={{ top: 15, bottom: 10, left: 10, right: 10 }} xhide={false} xdomain={[-3, 3]} xtickCount={7} xtick={[-3, -2, -1, 0, 1, 2, 3]} ydomain={[1, 4]} ytickCount={4} ytick={[1, 2, 3, 4]} data={data}></ScatterPlot>
                            <ScatterPlot height={220} margin={{ top: 30, left: 10, right: 10 }} xhide={true} xdomain={[-3, 3]} xtickCount={7} xtick={[-3, -2, -1, 0, 1, 2, 3]} ydomain={[1, 5]} ytickCount={5} ytick={[1, 2, 3, 4, 5]} data={data1}></ScatterPlot>
                            <div className='mt-3 d-flex justify-content-center'>
                                <button onClick={(e) => { openResultInterpretation() }} className='word-button '>{t("View Results Interpretation")}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SessionDetails;