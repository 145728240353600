
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const LOGOUT_URL = '/Auth/Logout';

const Logout = () => {
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const { t } = useTranslation();
    
    const [errMsg, setErrMsg] = useState('');

    const LogoutFunction = async () => {
 
        try { 
            const response = await axios.post(LOGOUT_URL, 
                JSON.stringify({'token': auth?.accessToken, 'refreshToken': auth?.refreshToken}), 
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                     },
                }
            );
            localStorage.removeItem('auth');
            setAuth( {} );
            navigate(from, { replace: true });
            window.location.reload();

            return response;
        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg('400');
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Logout failed');
            }
        }

        localStorage.removeItem('auth');
        setAuth( {} );
        navigate(from, { replace: true });
        window.location.reload();
    }


    return (
        <button onClick={() => { LogoutFunction() }} className="button btn-green">{t("Logout")}</button>
    )
} 

export default Logout