const DownloadParentReport = async (axiosPrivate, exerciseSessionId) => {
    try {
        const response = await axiosPrivate.get(`/ExerciseSessions/${exerciseSessionId}/ParentReport/pdf`, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `parent-report-${exerciseSessionId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        alert('Failed to download the report. Please try again.');
    }
};

export default DownloadParentReport;
