import { useEffect, useRef, useState } from 'react';
import { ReactSVG } from "react-svg";
import { useTranslation } from 'react-i18next';


function NavToolTip({ label, icon, options, selectedOption, onSelect }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const { t } = useTranslation();

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (value) => {
    onSelect(value);
    setShowTooltip(false);
  };

  return (
    <div className="nav-item" ref={tooltipRef}>
      <a onClick={toggleTooltip}>
        <ReactSVG src={icon} />
        <span>{label} ({selectedOption})</span>
      </a>

      {showTooltip && (
        <div className="tooltip-content">
          <ul>
            {options.map((option) => (
              <li key={option.value} onClick={() => handleSelect(option.value)}>
                {t(option.label)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default NavToolTip;
