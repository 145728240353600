import { useRef, useState, useCallback, useEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate.js';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { backBtn } from '../../../../icons/index.js';
import { ReactSVG } from "react-svg";
import CustomDatePicker from "../../../../components/util/CustomDatePicker.js";
import CustomInput from "../../../../components/util/CustomInput.js";
import CustomSelect from "../../../../components/util/CustomSelect.js";
import { languageObject, genderObject, handednessObject } from '../Objects.js';
import LoadingRing from '../../../../components/LoadingRing/index.js';
import { isEng } from "../../../../components/util/utils.js";

const EditPatient = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const errRef = useRef();
    const initialDate = new Date();
    initialDate.setFullYear(initialDate.getFullYear() - 5);
    const initialDateISO = initialDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [isLoading, setIsLoading] = useState(true);

    const [patientName, setPatientName] = useState('');
    const [patientLastName, setPatientLastName] = useState('');
    const [patientDOB, setPatientDOB] = useState(initialDateISO);
    const [patientGender, setPatientGender] = useState('male');
    const [patientLang, setPatientLang] = useState('');
    const [patientHandedness, setPatientHandedness] = useState('right');
    const [patientComment, setPatientComment] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [fullName, setFullName] = useState('');

    // const resetForm = () => {
    //     setPatientName('');
    //     setPatientLastName('');
    //     setPatientDOB(initialDateISO);
    //     setPatientGender('');
    //     setPatientLang('');
    //     setPatientHandedness('');
    // };

    const patientID = id;

    const getPatientDetails = useCallback(async () => {
        try {
            const response = await axiosPrivate.get(`/Patients/${id}`);
            const currentPatient = response.data;

            setPatientName(currentPatient.firstName);
            setPatientLastName(currentPatient.lastName);
            setPatientDOB(currentPatient.birthDate);
            setPatientLang(currentPatient.language);
            setPatientGender(currentPatient.gender);
            setPatientHandedness(currentPatient.handedness);
            setPatientComment(currentPatient.otherComments);

            setFullName(`${currentPatient.firstName} ${currentPatient.lastName}`);
            setIsLoading(false);

        } catch (err) {
            setErrMsg('Failed to fetch patient details');
            setIsLoading(false);
        }
    }, [axiosPrivate, patientID]);

    const editPatient = async () => {
        try {
            const response = await axiosPrivate.put(
                `/Patients/${patientID}`,
                JSON.stringify({
                    firstName: patientName,
                    lastName: patientLastName,
                    nickname: patientName,
                    language: patientLang,
                    handedness: patientHandedness,
                    gender: patientGender,
                    birthDate: patientDOB,
                    otherComments: patientComment,
                })
            );
            return response.data.id;
        } catch (err) {
            handleError(err);
            throw err;
        }
    };

    const handleError = (err) => {
        if (!err?.response) {
            setErrMsg(t('No server response'));
        } else if (err.response?.status === 400) {
            setErrMsg(err.response?.data);
        } else if (err.response?.status === 401) {
            setErrMsg(t('401 Unauthorized'));
        } else {
            setErrMsg(t('Request failed'));
        }
        errRef.current.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrMsg('');

        if ([patientName, patientLastName, patientDOB].includes('')) {
            setErrMsg(t('Please complete the details'));
            return;
        }

        setIsLoading(true);
        try {
            const patientId = await editPatient();
            navigate(`/view-patient?id=${patientId}`);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getPatientDetails();
    }, [getPatientDetails]);

    return (
        <>
        {isLoading ? (
           <LoadingRing />
       ) : (
        <div className="peili-main-wrapper">
            <div className="peili-views-wrapper">
                <h1>{t("Edit Patient")}</h1>
                <div className="peili-border"></div>
                <section>
                    <div className="mb-4">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h5 className="text-blue">{fullName}</h5>
                            </div>
                            <div className="col-md-6 text-end">
                                <Link className="button btn-yellow" to={`/view-patient?id=${patientID}`}>
                                    <ReactSVG src={backBtn} />
                                    <span>{t("BACK")}</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <section className="peili-form-wrapper">
                            <form id="Add-user-form" className="add-patient-form" onSubmit={handleSubmit} autoComplete="off">
                                <div className="row">
                                    <div className="col-md-6 item mb-5">
                                        <CustomInput
                                            attr={'firstName'}
                                            label={t('First Name')}
                                            placeholder={t("e.g. John")}
                                            value={patientName}
                                            setter={setPatientName}
                                            hasError={errMsg && patientName === ''}
                                        />
                                    </div>
                                    <div className="col-md-6 item mb-5">
                                        <CustomInput
                                            attr={'lastName'}
                                            label={t('Last Name')}
                                            placeholder={t("e.g. Smith")}
                                            value={patientLastName}
                                            setter={setPatientLastName}
                                            hasError={errMsg && patientLastName === ''}
                                        />
                                    </div>
                                    <div className="col-md-3 item mb-5">
                                        <CustomDatePicker
                                            attr={'patientDOB'}
                                            label={t('Date of Birth')}
                                            selectedDate={patientDOB}
                                            onDateChange={setPatientDOB}
                                            language={isEng() ? 'en' : 'fi'}
                                        />
                                    </div>

                                    <div className="item col-md-3 mb-5">
                                        <CustomSelect
                                            attr={'languageCode'}
                                            label={t("LANGUAGE TEST")}
                                            options={languageObject}
                                            value={patientLang}
                                            setter={setPatientLang}
                                        />
                                    </div>

                                    <div className="item col-md-3 mb-5">
                                        <CustomSelect
                                            attr={'gender'}
                                            label={t("Gender")}
                                            options={genderObject}
                                            value={patientGender}
                                            setter={setPatientGender}
                                        />
                                    </div>

                                    <div className="item col-md-3 mb-5">
                                        <CustomSelect
                                            attr={'handedness'}
                                            label={t("HANDEDNESS")}
                                            options={handednessObject}
                                            value={patientHandedness}
                                            setter={setPatientHandedness}
                                        />
                                    </div>

                                    <div className="col-md-12 item mb-5">
                                        <label htmlFor="others">{t("Other Information")}</label>
                                        <textarea
                                            rows="6"
                                            type="text"
                                            id="others"
                                            value={patientComment}
                                            onChange={(e) => setPatientComment(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="buttons-wrapper">
                                    <button className="button green-btn" type="submit">
                                        {t("Save")}
                                    </button>
                                </div>

                                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                    {errMsg}
                                </p>
                            </form>
                        </section>
                    </div>
                </section>
            </div>
        </div>
     )}
     </>
     
    );
};

export default EditPatient;
