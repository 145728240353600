import React from 'react';

const CustomInput = ({ attr, label, placeholder, value, setter, hasError, disable }) => {
    return (
        <div className={disable ? 'disable' : ''}>
            <label htmlFor={attr}>{label}</label>
            <input
                type="text"
                id={attr}
                placeholder={placeholder}
                onChange={(e) => setter(e.target.value)}
                value={value}
                className={!disable && hasError ? 'has-error' : ''}
                disabled={disable}
            />
        </div>
    );
};

export default CustomInput;
