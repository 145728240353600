import { useState, useRef, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import axios, { axiosPrivate } from '../api/axios';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Verification from './Verification';
const LOGIN_URL = '/Auth/Login';
const FORGOT_PASS_URL = '/auth/forgotpassword';
const Index = () => {
  const { t } = useTranslation();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const pwdRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [mfaType, setMfaType] = useState('SMS');
  const [mfaToken, setMfaToken] = useState('SMS');
  const [init, setInit] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const viewFirst = useRef('show');
  const viewSecondary = useRef('');
  const viewThird = useRef('');
  const forgotPassSuccess = useRef('');
  const [passwordType, setPasswordType] = useState("password");

  const [codes, setCodes] = useState(new Array(6).fill(''));

  useEffect(() => {
    setInit(false);
  }, [user, pwd, init]);


  const validateField = (e) => {
    if (e.value.length === 0) {
      e.classList.add('invalitaded');
    } else {
      e.classList.remove('invalitaded');
    }
  };

  const handleBack = () => {
    setInit(true);
    viewFirst.current = 'show';
    viewSecondary.current = '';
    viewThird.current = '';
    forgotPassSuccess.current = '';
    setCodes(new Array(6).fill(''));
    setErrMsg('');
  };

  const forgotPasswordView = () => {
    setInit(true);
    viewThird.current = 'show';
    viewFirst.current = '';
    viewSecondary.current = '';
    forgotPassSuccess.current = '';
    setErrMsg('');
  };

  const togglePassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const getUserData = async () => {
    try {
        const response = await axiosPrivate.get('/user',  {
            //signal: controller.signal
        });
        
        const data = response.data;

        let id = data.id;
        let name = data.firstName + ' ' + data.lastName;
        let email = data.email;
        let organisation = data.organisation;
        let language = data.language;
        let organisationId = data.organisation?.organisationId;
        let welcomeOnboardingShownDate = data.welcomeOnboardingShownDate;
        let resultsOnboardingShownDate  = data.resultsOnboardingShownDate;
        let userType = data.userType;
        let roles = data.roles;

        setAuth(prevAuth => ({
            ...prevAuth,
            id,
            name,
            email,
            organisation,
            language,
            organisationId,
            welcomeOnboardingShownDate,
            resultsOnboardingShownDate,
            userType,
            roles
        }));
        /*localStorage.setItem('auth', JSON.stringify({ 
            ...JSON.parse(localStorage.getItem('auth') || '{}'),
            id, 
            name, 
            email, 
            roles, 
            language, 
            organisation, 
            organisationId, 
            resultsOnboardingShownDate, 
            userType 
        }));*/
        //console.log(auth);
    } catch (err) {
      if (!err?.response) {
        //setErrMsg('No server response');
      } else if (err.response?.status === 400) {
        //setErrMsg('Invalid verification code');
      } else if (err.response?.status === 401) {
        //setErrMsg('401 Unauthorized');
      } else {
        //setErrMsg('Fetch failed');
      }
      //errRef.current.focus();
    }
  };

  const completeLogin = async (response) => {

    const {
      therapistId: id,
      accessToken,
      refreshToken,
      additionalRoles: roles,
      languageCode: language,
      organisation,
    } = response.data;
    const organisationId = organisation?.id;
    let loggedinTime = new Date().getTime();
    localStorage.setItem('auth', JSON.stringify({ id, user, pwd, roles, accessToken, refreshToken, language, organisation, organisationId }));
    localStorage.setItem('loggedinTime', loggedinTime);
    setAuth({ id, user, pwd, roles, accessToken, refreshToken, language, organisation, organisationId });
    setUser('');
    setPwd('');
    navigate(from, { replace: true });
    window.location.reload();
  };

  const preLogin = async (e) => {
    e.preventDefault();
    setErrMsg('');
    validateField(userRef.current);
    validateField(pwdRef.current);
    try {
      const response = await axios.post(LOGIN_URL, JSON.stringify({ 'username': user, 'password': pwd }), {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json'
        },
      });
      if (response.data.mfaRequired) {
        setMfaType(response.data.usedMfaType);
        setMfaToken(response.data.mfaToken);
        setInit(true);
        viewFirst.current = '';
        viewSecondary.current = 'show';
        
      }else{
        await completeLogin(response);
        getUserData();
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No server response');
      } else if (err.response?.status === 400) {
        setErrMsg('Invalid username or password');
      } else if (err.response?.status === 401) {
        setErrMsg('401 Unauthorized');
      } else {
        setErrMsg('Login failed');
      }
      errRef.current.focus();
    }
  };

  const codeInputFields = new Array(6).fill(0).map((item, index) => (
    <input
      name={`code-${index}`}
      key={index}
      className="verification-input"
      onChange={(e) => codeChangeHandler(e, index)}
      onKeyDown={(e) => handleKeyDown(e)}
      maxLength={1}
      value={codes[index]}
    />
  ));

  const codeChangeHandler = (e, index) => {
    
    const element = e.target;
    const nextSibling = element.nextElementSibling;
    if (nextSibling && element.value !== '') {
      nextSibling.focus();
    }
    const newCodes = [...codes];
    newCodes[index] = e.target.value;
    setCodes(newCodes);

    if (newCodes.every(code => code !== '')) {
      handleSubmit(e);
    }
  };
  const handleKeyDown = (event) => {
    const element = event.target;
    if (event.key === 'Backspace' && element.value === '') {
      const prevSibling = element.previousElementSibling;
      if (prevSibling) {
        prevSibling.focus();
      }
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setInit(true);
    let inputs = [];
    for (let i = 0; i < document.getElementsByClassName('verification-input').length; i++) {
      inputs.push(document.getElementsByClassName('verification-input')[i].value);
    }
    const verificationCode = inputs.join('');

    if (verificationCode === '' || verificationCode.length !== 6) {
      setErrMsg('Invalid Token');
      errRef.current.focus();
    } else {
      let loggedinTime = new Date().getTime();
      try {
        const response = await axios.post(
          LOGIN_URL,
          JSON.stringify({
            username: user,
            password: pwd,
            preferredMfaType: mfaType,
            mfaToken: mfaToken,
            mfaCode: verificationCode,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
              accept: 'application/json',
            },
          }
        );
        
        const {
          therapistId: id,
          accessToken,
          refreshToken,
          additionalRoles: roles,
          languageCode: language,
          organisation,
        } = response.data;
        const organisationId = organisation?.id;
        let loggedinTime = new Date().getTime();
        localStorage.setItem('auth', JSON.stringify({ id, user, pwd, roles, accessToken, refreshToken, language, organisation, organisationId }));
        localStorage.setItem('loggedinTime', loggedinTime);
        setAuth({ id, user, pwd, roles, accessToken, refreshToken, language, organisation, organisationId });
        setUser('');
        setPwd('');
        navigate(from, { replace: true });
        window.location.reload();

        navigate(from, { replace: true });
        window.location.reload();
      } catch (err) {
        if (!err?.response) {
          setErrMsg('No server response');
        } else if (err.response?.status === 400) {
          setErrMsg('Invalid verification code');
        } else if (err.response?.status === 401) {
          setErrMsg('401 Unauthorized');
        } else {
          setErrMsg('Login failed');
        }
        errRef.current.focus();
      }
    }
  };
  const forgotPassword = async (e) => {
    e.preventDefault();

    setInit(true);
    try {
      const response = await axios.post(FORGOT_PASS_URL, { email: user });
      if (response.data) {
        setInit(true);
        forgotPassSuccess.current = 'show';
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No server response');
      } else if (err.response?.status === 400) {
        setErrMsg('Unregistered username');
      } else if (err.response?.status === 401) {
        setErrMsg('401 Unauthorized');
      } else {
        setErrMsg('Unregistered username');
      }
      errRef.current.focus();
    }
  };
  return (
    <div className="peili-content-wrapper ">
      <Login
        t={t}
        userRef={userRef}
        pwdRef={pwdRef}
        setUser={setUser}
        setPwd={setPwd}
        preLogin={preLogin}
        togglePassword={togglePassword}
        passwordType={passwordType}
        viewFirst={viewFirst}
        forgotPasswordView={forgotPasswordView}
      />
    <Verification
        t={t}
        handleSubmit={handleSubmit}
        handleBack={handleBack}
        preLogin={preLogin}
        mfaType={mfaType}
        codeInputFields={codeInputFields}
        errMsg={errMsg}
        errRef={errRef}
        viewSecondary={viewSecondary}
    />
      <ForgotPassword
        t={t}
        handleBack={handleBack}
        forgotPassword={forgotPassword}
        userRef={userRef}
        setUser={setUser}
        viewThird={viewThird}
        forgotPassSuccess={forgotPassSuccess}
      />
      <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">{errMsg}</p>
    </div>
  );
};
export default Index;