import axios from 'axios';

//const BASE_URL = 'https://peili-efsim-webapi-dev.azurewebsites.net/api/';

let BASE_URL;

const currentURL = window.location.href;

if (currentURL.includes('https://arvo-dev.azurewebsites.net/')) {
    BASE_URL = 'https://peili-efsim-webapi-dev.azurewebsites.net/api/';
} else if (currentURL.includes('https://efsim-staging.azurewebsites.net/')) {
    BASE_URL = 'https://peili-efsim-webapi-staging.azurewebsites.net/api';
} else if (currentURL.includes('https://efsim.azurewebsites.net/')) {
    BASE_URL = 'https://peili-efsim-webapi.azurewebsites.net/api/';  
} else {
    BASE_URL = "https://peili-efsim-webapi-dev.azurewebsites.net/api/"; // Fallback to environment variable 
}

//https://peilieduarvowebapi.azurewebsites.net/api/ OIKEA
//https://peili-edu-dev.azurewebsites.net/api/ DEV 

//MALTTI
//https://peili-maltti-webapi.azurewebsites.net/api Prod 
//https://peili-maltti-webapi-dev.azurewebsites.net dev

//EFSIM
//https://peili-efsim-webapi-dev.azurewebsites.net/api/ dev
//https://peili-efsim-webapi.azurewebsites.net/api/ Prod
//https://peili-efsim-webapi-staging.azurewebsites.net/api/ staging

export default axios.create({
    baseURL: BASE_URL 
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
        'accept': 'application/json'
     },
     //withCredentials: true
});