import React from 'react';
import { useTranslation } from 'react-i18next';

export default function KnowledgeBaseIntro() {

    const { t } = useTranslation();
    const {title, description} = t("knowledge_base_intro");

  return (
    <div className="sub-page">
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  )
}
