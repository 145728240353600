export const languageObject = {
    'English': 'en-GB',
    'Finnish': 'fi-FI'
};

export const genderObject = {
    'Male': 'male',
    'Female': 'female',
    'Other': 'other',
};

export const handednessObject = {
    'Right': 'right',
    'Left': 'left',
    'Unknown': '0',
};

export const relationObject = {
    'Parent': 'parent',
    'Guardian': 'guardian',
};